
.mnuContent {

    position: fixed;
    top: -10em;

    display: none;
    justify-content: space-evenly;

    width: 100%;
    height: 5em;
    /* background: rgba(232, 232, 232, 0.95); */
    background: rgba(255, 255, 255, 1);
    box-shadow: 0 0px 5px rgba(0, 0, 0,.7);
    opacity: 0;
    /* transition: opacity 2s, top .7s; */

    z-index: 1000;

}

.mnuContentOp {

    top: 0em;

    opacity: 1;

}

.logo {

  /* width: 17%; */
  height: 100%;
  object-fit: cover;
  align-items: center;
  margin: 0 3%;
  display: flex;

}

.logo img, .logo a {

  height: 100%;

}

.navUl {

    display: flex;
    justify-content: space-between;

    /* font-size: 1em; */
    color: black;

    width: 50%;
    list-style: none;

    /* border: 1px solid red; */

}

.navUl li {

    margin: auto;
    padding: .5em;
    background-image: linear-gradient(red, red);
    background-position: 0% 100%;
    background-repeat: no-repeat;
    background-size: 0% 2px;
    transition: background-size .3s;

}

.navUl li:hover {

    background-size: 100% 2px;

}

.navUl p, .navUl a {

    font-size: 1em;
    font-weight: bold;

}

@media screen and (min-width: 800px) {

    .mnuContent {

        display: flex;

    }
    
}