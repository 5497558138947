
.cardContent {

    width: 50%;
    height: 7em;
    background: rgba(255, 255, 255, 0.9);
    margin: 0.7em auto;
    display: flex;
    border: 1px solid rgba(173, 173, 173, 0.8);
    border-radius: 5px;
    justify-content: space-around;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.6);
    align-items: center;
    animation: toLeftListPub 0.8s;

}

@keyframes toLeftListPub {

    from {

        width: 30%;

    }

    to {

        width: 50%;

    }
    
}

.cardContent > img {

    width: 10%;
    margin: 0.5em;

}

.cardContent > h3 {

    font-size: 1.5em;
    margin: auto;

}

.cardContent > input {

    width: 6em;
    height: 2.5em;
    background:rgba(255, 255, 255, 0.5);
    color: black;
    margin: auto;
    padding: 0.2em 0;
    border-radius: 5px;
    border: 1px solid red;

}

.cardContent > input:hover {

    background: rgba(253, 23, 23, 0.7);
    color: white;
    cursor: pointer;

}

.cardContent-text {

    font-size: 1em;
    margin: auto;

}

.cardContent > div {

    margin: auto;

}

.cardContent-textHover {

    width: 5em;
    height: 2em;
    color: black;
    margin: auto;
    padding: 0.2em 0;
    border-radius: 5px;
    border: 1px solid red;

}

.cardContent-textHover:hover {

    background: rgba(253, 23, 23, 0.7);
    color: white;

}

/* De esta parte para abajo esta todo lo responsive */

@media screen and (min-width: 1201px) {

    .cardContent > img {

        width: 11%;

    }
    
}

@media screen and (max-width: 1200px) {

    .cardContent {

        width: 70%;

    }

    .cardContent > img {

        width: 12%;

    }

}

@media screen and (max-width: 900px) {

    .cardContent {

        width: 90%;

    }
    
    .cardContent > img {

        width: 12%;

    }

}

@media screen and (max-width: 750px){

    .cardContent > img {

        width: 15%;

    }
    
}
/*  */
@media screen and (max-width: 700px) {

    .cardContent > h3 {

        font-size: 1.3em;

    }

    .cardContent-text {

        width: 25%;
        font-size: 0.9em;

    }

}