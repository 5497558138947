.ContainerPG {

    /* height: 50em; */
    /* background: rgba(232, 232, 232, 1); */
    background: rgba(255, 255, 255, 1);
    /* padding: 0 0 3em 0; */

}

.containerGroup {

    width: 100%;
    /* height: 42em; 126 */
    height: 100%;
    margin: 2em auto;
    padding: .5em;
    overflow: hidden;
    transition: all 3s ease-in-out;

    /* border: 1px solid red; */

}

.gridGroup {

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: .5em;

}

.contentCard {

    position: relative;

    transition: all 1.2s;

}

.contentCard:nth-child(even) {

    right: -200em;

}

.contentCard:nth-child(odd) {

    left: -200em;

}

.ContainerPG h1 {

    font-size: 2em;
    padding: 0 0 0 0;
    /* line-height: 3em; */
    text-align: center;

}

.btnVerMas {

    display: flex;
    justify-content: center;
    vertical-align: middle;
    align-items: center;

    width: 10%;
    height: 3em;
    margin: 2em auto 0;
    font-weight: bold;

    border: 1px solid red;

}

.btnVerMas:before {
    
    content: "VER MAS";

}

.btnVerMas:hover {

    cursor: pointer;
    background: rgba(182, 0, 0, 0.8);
    color: #fff;

}

.btnVerMasClose:before {

    content: "VER MENOS";

}

@media screen and (min-width: 800px) {

    .gridGroup {

        grid-template-columns: repeat(2, 1fr);

    }

    .containerGroup {

        width: 90%;

    }
    
}

@media screen and (min-width: 1200px) {

    .ContainerPG h1 {

        font-size: 3em;

    }

    /* .containerGroup {

        padding-top: 1em;

    } */

    .gridGroup {

        grid-template-columns: repeat(3, 1fr);

    }
    
}