.BannerFinalSecond {
  position: relative;

  display: flex;
  overflow: hidden;
  flex-wrap: nowrap;

  width: auto;
  height: 100%;
  max-height: 100vh;
  /* filter: brightness(70%); */

  /* z-index: 2; */
}

/* HEIGHT POP UP */

/* .tamPopup {
  
    height: 26em;
  
  } */

/* .tamPopup > img {
  
    filter: brightness(1000%);
  
  } */

/* HEIGTH POP UP */

.ContenedorSlideSecond {

  display: flex;
  flex-wrap: nowrap;

  transition: all 2s;

}

.ContentArrow {

  position: fixed;

  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 100%;

  z-index: 10000;

}

.stopSec {

  position: absolute;
  top: 30%F;

  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  opacity: 0;
  transition: opacity 1s;

  z-index: 1;

}

.stopSec:hover {

  cursor: pointer;
  opacity: 1;

}

.stopSec p {

  position: flex;
  justify-content: center;

  display: inline-block;

  font-size: 1.5em;
  font-weight: bold;
  width: 10em;
  margin: 15% 40%;
  color: white;
  border: 1px solid rgb(255, 255, 255);
  text-align: center;

}

.stopSec p:hover {

  color: rgb(0, 0, 0);
  background: rgba(255, 255, 255, 0.7);
  border: 1px solid rgb(0, 0, 0);

}

.stopSecActive {

  pointer-events: none;
  cursor: default;

}

.arrowLeft,
.arrowRight {

  position: fixed;
  top: 30%;

  background: transparent;
  margin: 2em;
  padding: .2em;
  border: 1px solid rgba(201, 1, 1, 0);
  border-radius: 50%;
  filter: blur(3em);
  transition: all .7s, filter 1s;

  z-index: 100;

}

.ContentArrowFilter {

  filter: blur(0em);

}

.arrowLeft:hover,
.arrowRight:hover {

  cursor: pointer;
  background: rgba(232, 232, 232, 0.7);
  border: 1px solid red;

}

.arrowLeft {

  transform: rotate(270deg);

}

.arrowRight {

  right: 0;

  transform: rotate(90deg);

}

/*===== Responsive ======*/
@media screen and (max-width: 1250px) {
  .BannerTitulo {
    font-size: 3rem;
  }
}

@media screen and (max-width: 1024px) {

  .stopSec p {

    margin: 27% 30%;

  }

}

@media screen and (max-width: 800px) {

  .BannerFinalSecond {
    height: 100%;
  }

  .stopSec {
  
    top: 0%F;
  
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    opacity: 0;
    transition: opacity 1s;
  
    z-index: 1;
  
  }
  
  .stopSec p {
  
    font-size: 1.5em;
    font-weight: bold;
    width: 10em;
    margin: 33% 25%;
    text-align: center;
  
  }
  
  .stopSec p:hover {
  
    color: rgb(0, 0, 0);
    background: rgba(255, 255, 255, 0.7);
    border: 1px solid rgb(0, 0, 0);
  
  }

}

@media screen and (max-width: 500px) {
  .BannerTitulo {
    font-size: 1.5rem;
  }

  .BannerSubtitulo {
    margin-top: 1px;
    font-size: 1.2rem;
  }

  .BotonBanner {
    margin-top: 8px;
    max-width: 95px;
    padding: 5px 0px;
    font-size: 1rem;
  }
}