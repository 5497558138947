
.listUserContent {

    background: url(../../../images/fondos/20-12\ FONDO\ WEB-02.jpg) no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    border: 1px solid #000000;
    min-height: 100vh;

}

.listUserContent > h1 {

    text-align: center;
    margin: 0.5em 0 0 0;

}

.listUserContent__busqueda {

    width: 80%;
    margin: 0.5em auto 1em;
    display: flex;
    justify-content: space-around;

}

.listUserContent__busqueda > input {

    height: 2.5em;
    padding: 0.5em;
    border: 1px solid #000000;
    border-radius: 3px;

}

.busqueda__buscador {

    width: 25%;
    height: 2em;
    display: flex;
    justify-content: space-between;

}

.busqueda__buscador > input {

    height: 2.5em;
    padding: 0.5em;

}

.buscador__buttom {
    
    width: 40%;
    background: rgba(255, 255, 255, 0.9);
    color: rgb(255, 0, 0);
    border: 1px solid #000000;
    border-radius: 5px;
    align-items: center;
    display: flex;
    justify-content: center;

}

.buscador__buttom > p {

    margin: 0 0.5em;

}

.buscador__buttom:hover {

    background: rgba(255, 0, 0, 0.9);
    color: rgb(255, 255, 255);
    cursor: pointer;

}

@media screen and (min-width: 1201px) {

    .busqueda__buscador {

        width: 30%;

    }

    .busqueda__buscador > input[type="text"] {

        width: 60%;

    }

    .buscador__buttom {

        width: 35%;

    }

    .buscador__buttom > p { 

        margin: 0 .3em;

    }
    
}

@media screen and (max-width: 1200px) {

    .busqueda__buscador {

        width: 30%;

    }

    .busqueda__buscador > input[type="text"] {

        width: 60%;

    }

    .buscador__buttom {

        width: 35%;

    }

    .buscador__buttom > p { 

        margin: 0 .3em;

    }

    
}

@media screen and (max-width: 1000px) {

    .busqueda__buscador {

        width: 40%;

    }

    
}

@media screen and (max-width: 800px) {

    .listUserContent__busqueda > input {

        width: 20%;

    }

    .busqueda__buscador {

        width: 40%;

    }

    .buscador__buttom > p { 

        font-size: .9em;
        margin: 0 .1em;

    }

    
}

@media screen and (max-width: 700px) {

    .listUserContent__busqueda > input {

        width: 30%;

    }

    .busqueda__buscador {

        width: 30%;

    }

    .busqueda__buscador > input[type="text"] {

        width: 75%;

    }

    .buscador__buttom {

        width: 2em;
        border-radius: 50%;

    }

}