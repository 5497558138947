.contactContainer {

    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 1em;
    grid-auto-rows: minmax(1fr, auto);

    width: 90%;
    height: 200%;
    margin: 0 auto;

}

.content1 {

    grid-column: 1 / 2;
    grid-row: 1;

    padding: 0 1.5em;

}

/* .content1 h1 {

    font-size: 1.5em;

} */

.content2 {

    grid-column: 1 / 2;
    grid-row: 2 / 2;

}

.content2 p,
.content2 h2 {

    width: 95%;
    margin: 1em auto;

}

.content4 {

    grid-column: 1 / 2;
    grid-row: 3 / 3;

    padding: 0 1em;

}

.content4 ul li {

    list-style: none;

}

.content4 ul li:before {

    font-size: 1.3em;
    font-weight: bold;
    content: "- ";

}

.content4 p {

    margin: 0.5em;

}

.contentForm {

    width: 95%;
    margin: .5em auto;

}

.contentForm p {

    font-size: .8em;
    margin: 0;
    padding: 0;

}

.contentForm form {

    margin: 0 0 0.5em;

}

.contentForm label input {

    margin: 0 .5em;

}

.content3 {

    display: none;

}

.content5 {

    grid-column: 1 / 2;
    grid-row: 5 / 5;

    height: 25em;

}

.content6 ul li {

    list-style: none;

}

.content6 ul li:before {

    font-size: 1.3em;
    font-weight: bold;
    content: "- ";

}

.contentMap,
.img1 {

    height: 100%;

}

.contentMap {

    padding: 0.5em;
    border: 1px solid #000;

}

.gmap_iframe {

    width: 100% !important;
    height: 100% !important;

}

.img1 {

    background: #999;
    text-align: center;
    vertical-align: middle;
    padding: 10em;

}

/*===== Formulario =====*/

form {
    margin-top: 2%;
    width: 110%;
    border-radius: 10px;
    background-color: rgba(245, 245, 245, 0);
}

form input[type="text"],
form input[type="email"],
form input[type="number"] {
    width: 90%;
    padding: 10px 10px;
    border: 1px solid #383535;
    border-radius: 3px;
    background-color: #f6f6f600;
    margin: 8px 0;
    display: inline-block;
}

form input[type="submit"] {
    width: 90%;
    padding: 10px 10px;
    margin-top: 10px;
    border: 1px solid #000;
    border-radius: 5px;
    display: block;
    color: rgb(255, 255, 255);
    background-color: #000;
}

form input[type="submit"]:hover {
    cursor: pointer;
}

textarea {
    width: 90%;
    height: 100px;
    border: 1px solid #2b2828;
    border-radius: 3px;
    background-color: #f6f6f600;
    padding: 10px 10px;
    margin: 8px 0;
    /*resize: vertical | horizontal | none | both*/
    resize: none;
    display: block;
}

@media screen and (min-width: 800px) {

    .contactContainer {

        grid-template-columns: repeat(2, 1fr);

        margin-top: 2em;

    }

}

@media screen and (min-width: 1000px) {

    .contactContainer {

        width: 95%;

    }

    .content2 {

        grid-column: 1 / 2;
        grid-row: 2 / 3;

    }

    .content3 {

        display: block;

        grid-column: 2 / 3;
        grid-row: 2 / 3;

    }

    .content5 {

        grid-column: 2 / 3;
        grid-row: 1 / 3;

        height: 100%;

    }

    .content6 {

        grid-column: 2 / 3;
        grid-row: 3 / 4;

        /* height: 25em; */

    }

}