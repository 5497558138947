
.CreateTestimonyContent {

    width: 100%;
    height: 100vw;
    background: url(../../../images/fondos/20-12\ FONDO\ WEB-02.jpg) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    border: 1px solid #000000;
    text-align: center;

}

.CreateTestimonyContent__fomr {

    width: 80%;
    background: rgba(0, 0, 0, 0.8);
    margin: 1em auto;
    padding: 1em;
    border: 1px solid #000000;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: .7em;

}

.form__info {

    display: flex;
    justify-content: space-evenly;

}

.form__info > input[type="text"], .contText > textarea {

    border: 1px solid rgb(255, 255, 255);
    margin: 0 auto;
    color: #fff;

}

.contText {

    height: 6.5em;

}

.contText > textarea {

    height: 7.5em;
    transition: all 2s;

}

.form__info > input[type="text"] {

    width: 45%;

}

.form__info > input[type="text"]::placeholder, .contText > textarea::placeholder {

    color: #fff;

}

.linkYoutube {

    /*Box-Models*/
    width: 40%;
    display: flex;
    justify-content: space-evenly;
    margin: 0 auto;

    /*Misc*/
    grid-column: 1 / 3;

}

.linkYoutube p {

    /*Bos-Model*/
    margin: auto;

    /*Tipography*/
    color: #fff;

}

.linkYoutube input {

    /*Box-Model*/
    width: 70%;
    height: 2.5em;

}

.CreateTestimonyContent__fomr > input[type="file"] {

    height: 3em;
    color: #fff;
    margin: 2.5em 0 0 5%;

}

.CreateTestimonyContent__fomr > input[type="file"]::-webkit-file-upload-button {

    width: 30%;
    height: 2.5em;
    color: white;
    display: inline-block;
    background: rgba(167, 1, 1, 0.6);
    border: 1px solid rgba(255, 0, 0, 1);
    font-weight: 700;
    border-radius: 3px;
    white-space: nowrap;
    cursor: pointer;
    font-size: 10pt;

}

.CreateTestimonyContent__fomr > input[type="file"]::-webkit-file-upload-button:hover {

    background: rgba(255, 0, 0, 0.6);

}

.aparece {

    animation: transparncyOn .7s;

}

.CreateTestimonyContent__fomr > img {

    width: 9%;
    position: absolute;
    left: 75%;
    top: 11em;
    border-radius: 50%;
    box-shadow: 0 0 1em rgba(255, 255, 255, 0.9);

}

.CreateTestimonyContent__fomr > input[type="submit"] {

    width: 50%;
    grid-column: 1 / 3;
    margin: 0 auto;
    font-size: 1.2em;

}

.Select {

    display: flex;

}

.Select > select {

    width: 80%;
    display: block;

}

.agregar {

    padding: .2em 0 0 0;

}

.agregar:hover {

    cursor: pointer;

}

.contentEntFiles {

    grid-column: 1 / 3;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 1em;
    margin: 0;
    padding: 0;

}

.contentEntFile {

    width: 50%;
    height: 3em;
    background: rgba(168, 0, 0, 0.6);
    border: 1px solid red;
    color: white;
    position: relative;
    border-radius: 3px;
    margin: 0 auto;

}

.contentEntFile > p {

    width: 100%;
    height: 3em;
    border: 1px solid red;
    padding: .7em .5em;
    position: absolute;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

}

.contentEntFile > p:hover {

    cursor: pointer;

}

.contentEntFile > input[type="file"] {

    opacity: 0;
    border: 1px solid rgb(255, 255, 255);
    width: 100%;
    height: 3.5em;
    display: none;
    position: relative;
    margin: 0 auto;
    
}

.contentEntFile > input[type="file"]:hover {

    cursor: pointer;

}

@keyframes aparece {

    from {

        height: 0;
        font-size: 0;

    }

    to {

        height: 1.5em;
        font-size: 1em;

    }

}

@keyframes desaparece {

    from {

        height: 1.5em;
        font-size: 1em;

    }

    to {

        height: 0;
        font-size: 0;

    }

}

.textAlert {

    height: 0;
    background: linear-gradient(90deg, rgba(255,0,0,.6) 0%, rgba(255,0,0,0) 60%);
    font-size: 0;
    color: #fff;
    text-align: left;
    grid-column: 1 / 6;
    padding: 0 2em;
    animation: desaparece .7s;

}

.textAlertActive {

    height: 1.5em;
    background: linear-gradient(90deg, rgba(255,0,0,.6) 0%, rgba(255,0,0,0) 60%);
    color: #fff;
    text-align: left;
    grid-column: 1 / 6;
    padding: 0 2em;
    animation: aparece .7s;

}

.subText {

    grid-column: 1 / 3;
    background: linear-gradient(90deg, rgba(255,0,0,0) 20%, rgba(255,0,0,0.7) 50%, rgba(255,0,0,0) 80%);
    color: #fff;

}