nav, nav a, nav ul a {

  padding: 0px;
  margin: 0px;
  height: auto;
  font-size: auto;

}

.Cont {

  position: static;

  z-index: 90;
}

/*===== Logo Importainer =====*/

.logoC {

  /* width: 30%; */
  height: 5%;
  object-fit: cover;
  align-items: center;
  /* margin: 0 3%; */
  display: flex;

}

.logoC img {

  display: none;

  width: 100%;

}

.logo {
  opacity: 0.8;
}

.container {

  margin: auto;
  overflow: hidden;
  z-index: 4;

}

/*===== Header =====*/

.menuBar {

  position: absolute;
  /* top: 1em; */
  /* right: 10em; */

  display: flex;
  justify-content: space-evenly;

  margin-top: 1em;

}

.menuBtn {

  position: absolute;
  top: 5em;
  right: 1em;

  display: block;

  padding: .5em;
  align-items: center;
  color: #fff;

  z-index: 100;

}

.navMenu {

  position: fixed;
  top: 0;
  right: 0;

  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.61);
  align-items: center;
  justify-content: center;
  transform: translateX(500%);
  transition: transform 0.8s ease-in-out;

  z-index: 100;

}

.show {

  transform: translateX(0%);
  background: rgba(0, 0, 0, .7);
  backdrop-filter: blur(2px);

}

.faTimes {
  transition: 2500ms;
  color: #ffffff;
}

.navMenu ul {
  display: block;
  position: absolute;
  top: 25%;
  left: 30%;
  padding-top: -300px;
  list-style: none;

}

.navMenu ul li {

  font-weight: 500;

  /* background-image: linear-gradient(red, red);
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 0% 2%;
  transition: background-size .3s; */
  padding: 0em;
  margin-top: 5em;
  /* margin-left: 1em; */

}

.navMenu li:hover {

  /* background-size: 100% 2%; */

  /* color: red; */
  /* text-decoration: underline; */

}

/* .navMenu ul li p {

  color: #fff;
  margin: 0;

} */

.redSocBar {

  position: absolute;
  top: 0px;

  width: 100%;

  z-index: 100;

}

p {
  font-size: 1rem;
}

.navRight {

  /* top: 28%; */

  display: flex;
  justify-content: space-between;

  font-size: 1.2em;
  color: black;

  width: 30%;
  list-style: none;

  /* width: 35%; */
  /* border: 1px solid red; */

}

.navRight li {

  margin: auto;
  background-image: linear-gradient(#f00, #f00);
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 0% 2px;
  transition: background-size .3s, font-size .5s;

}

.navRight li:hover {

  font-size: 1.1em;
  background-size: 100% 2px;

}

.navRight p {

  display: block;

  font-size: 1.2em;
  font-weight: bold;
  text-align: center;

}

/*===== Responsive =====*/

@media screen and (min-width: 800px) {

  .Cont {

    position: absolute;
    top: 0vh;

    width: 100%;
    background: rgba(0, 0, 0, 1);

  }

  .logoC {

    /* width: 15em; */
    height: 100%;

  }

  .logoC img, .logoC a {

    display: block;

    /* width: 15em; */
    height: 100%;

  }

  .container {

    padding: 50px 0;

  }

  .menuBtn {

    display: none;

  }

  .menuBar {

    right: 0em;

    display: flex;

    width: 100%;
    height: 5em;

  }

  .navMenu {

    position: relative;

    /* justify-content: flex-end; */

    width: 60%;
    background: rgba(0, 0, 0, 0);
    /* margin: 0 1em 0 0; */
    transform: translateX(0%);

  }

  .navMenu ul {

    position: relative;
    right: 1em;
    left: 0;

    display: flex;

    text-align: right;

    width: auto;

  }

  .navMenu ul li {

    width: 100%;
    margin: 0;

  }

  .navRight {

    top: 0%;

  }

  .navRight p {

    font-size: .9em;

    padding: 0em;
    margin: .5em;

  }

}

@media screen and (max-width: 425px) {

  /* .menuBtn {

    left: 25em;  
  
  } */
  
}