.cardProductContent {

    position: relative;

    background-color: #ffffff;
    width: 100%;
    /* height: 46em; */
    /* height: 100%; */
    overflow: hidden;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
    margin: 0 auto;

}

.headband {

    position: absolute;
    /* top: 3em;
    right: -16%; */

    top: 1em;
    right: -90%;

    width: 20em;
    color: #ffffff;
    padding: .1em;
    text-align: center;

    transform: rotate(45deg);

}

.headband h3 {

    font-weight: 1000;
    font-size: .7em;

}

.headbandRed {

    background: rgba(255, 55, 55, 0.8);
    border: 1px solid red;

}

.headbandBlack {

    background: rgba(145, 145, 145, 0.8);
    border: 1px solid rgb(202, 202, 202);

}

.headbandGris {

    background: rgba(0, 0, 0, 0.8);
    border: 1px solid rgb(0, 0, 0);

}

.OfertaCardTitle {
    margin-top: 15px;
    margin-bottom: 15px;
    margin-left: 10px;
}

.OfertaCardTop {
    position: relative;
    display: flex;
    align-items: center;
    height: 60px;
}

.Descuento {
    position: absolute;
    right: 20px;
    color: rgb(255, 0, 0);
    font-size: 25px;
}

.OfertaContImg {
    /* width: 93%; */
    margin-left: auto;
    margin-right: auto;
}

.cardProductContent img {

    width: 100%;
    /* height: 15em; */
    height: 10em;
    object-fit: cover;

}

.OfertaCarac {

    /* display: flex;
    justify-content: space-around; */

    display: none;

    margin: .5em 0 0 0;
    font-size: 1.1em;

}

.priceAdhe {

    display: flex;
    flex-direction: column;
    justify-content: center;

    text-align: center;

    height: 2.5em;
    margin: 1em auto;


    background-image: linear-gradient(red, red);
    background-position: 100% 0%;
    background-repeat: no-repeat;
    background-size: 0% 2px;
    transition: background-size .3s;
    animation: lineaAnimated 1s linear 2s forwards;

    

}

.priceAdhe p {

    font-size: .8em;
    font-weight: bold;
    /* animation: name duration timing-function delay iteration-count direction fill-mode; */
}

.boxAde {

    position: absolute;

    
    width: 100%;
    height: 2.5em;

    
    background-image: linear-gradient(red, red);
    background-position: 0% 100%;
    background-repeat: no-repeat;
    background-size: 0% 2px;
    transition: background-size .3s;
    animation: lineaAnimatedBotom 1s linear 2s forwards;

    /* border: 1px solid red; */

}

/* .animatedBorderTop {

    background-image: linear-gradient(red, red);
    background-position: 100% 0%;
    background-repeat: no-repeat;
    background-size: 0% 2px;
    transition: background-size .3s;
    animation: lineaAnimated 1s linear forwards;

}

.animatedBorderBotom {

    background-image: linear-gradient(red, red);
    background-position: 0% 100%;
    background-repeat: no-repeat;
    background-size: 0% 2px;
    transition: background-size .3s;
    animation: lineaAnimatedBotom 1s linear forwards;

} */

@keyframes lineaAnimated {

    0% {

        background-size: 0% 2px;
    
    }

    100% {

        background-size: 100% 2px;

    }

}

@keyframes lineaAnimatedBotom {

    0% {

        background-size: 0% 2px;
    
    }

    100% {

        background-size: 100% 2px;

    }

}

/* -webkit-text-stroke: 0.2px #000;
-webkit-text-fill-color: rgba(0,0,0,0); */

@keyframes borderActive {

    0% {

        border-top: 2px solid red;
        border-bottom: 2px solid red;

    }

    50% {

        border-top: 2px solid brown;
        border-bottom: 2px solid brown;
    }

    100% {

        border-top: 2px solid red;
        border-bottom: 2px solid red;
    }

}

.OfertaCardSubtitle1,
.OfertaCardSubtitle2 {

    /* display: none; */

    font-size: .7em;
    text-align: center;
    margin: .5em 0;

}

.OfertaCardSubtitle2 {

    display: none;

}

.contentCuot,
.contentPrecios {

    width: 95%;
    background: rgba(232, 232, 232, 0.8);
    margin: 1em auto;
    padding: .5em;

}

.contentCuot h4 {

    font-size: .7em;

}

.contentPrecios {

    display: grid;
    grid-template-columns: repeat(2, 1fr);

    font-size: .7em;

}

.contBtnCuot {

    display: flex;
    justify-content: space-between;

}

.btnCuot120,
.btnCuot240 {

    border: 1px solid black;
    width: 47%;
    text-align: center;
    height: 2em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    margin: 0.5em 0;
    font-weight: bold;

}

.btnCuot120 p,
.btnCuot240 p {

    font-size: .8em;

}

.cuotActive {

    color: red;

}

.preNumCuot,
.preTextCuot {

    font-size: .9em;

}

.preText {

    display: none;

    grid-column: 1 / 3;

}

.preNum {

    /* display: flex;
    justify-content: flex-end; */

    display: none;

}

.contentPrecios p:nth-child(0n+1) {

    display: block;

}

.preText,
.preNum {

    font-size: 1em;

}

.contentBtn {

    display: flex;
    justify-content: space-around;

}

.btnVer,
.btnCon {

    display: flex;
    justify-content: center;

    width: 40%;
    border: 1px solid black;
    margin: 0 0 1em 0;
    padding: .5em;
    font-weight: bold;

}

.btnVer {

    flex-direction: column;

    font-size: .6em;

    height: 3em;
    text-align: center;
    background: rgba(0, 0, 0, 1);
    color: white;

}

.btnCon p {

    font-size: .5em;

    color: black !important;

}

.btnVer:hover,
.btnCon:hover,
.btnCon:hover p {

    background: rgba(185, 0, 0, 1);
    color: white !important;
    cursor: pointer;

}

.sangria {
    text-indent: 1em;
    text-decoration: none;
}

.OfertaCardDesc {
    width: 90%;
    margin: auto;
    margin-top: 15px;
}

.PopupContent {

    width: 70em;
    background: rgba(232, 232, 232, .8);
    border: 1px solid rgba(185, 0, 0, 1);
    margin: 1em 0;

}

.PopupClouse {

    width: 1.5em;
    height: 1.5em;
    border: 1px solid rgba(185, 0, 0, 1);
    margin: 0 .5em;

}

.PopupClouse:hover {

    cursor: pointer;

}

.PopupHeader {

    display: flex;
    flex-direction: column;
    justify-content: center;

    padding: 1em 5em;

}

.PopupHeader img {

    height: 25em;

}

/* -------------------- POP UP ----------------------------- */

.contentPopup {

    position: fixed;
    top: 100vh;
    left: -15%;

    width: 10%;
    height: 10%;
    background: rgba(232, 232, 232, 0.5);
    opacity: 0;
    transition: all .7s ease-in-out;

    z-index: 100;

    border: 1px solid white;

}

.maxWidth {

    top: 1em;
    left: 2.5%;

    width: 95%;
    height: 70%;
    opacity: 1;

}

.closePop {

    display: flex;
    justify-content: flex-end;

    background: rgba(0, 0, 0, 1);
    margin: 0 0 .5em;
    padding: .2em;

}

.closePopIcon {

    display: flex;

    padding: 0;
    /* border: 1px solid red; */

}

.closePopIcon:hover {

    cursor: pointer;

}

.containerP {

    width: 90%;
    height: 92%;
    margin: 0 5%;
    /* filter: blur(2em); */
    overflow: scroll;

}

.contentImg {

    /* height: 70%; */
    margin: 0 auto;

    /* border: 1px solid red; */

}

.imgSlider {

    position: relative;

    display: flex;

    height: 100%;
    transform: translateX(0em);
    transition: transform 2s;

    /* border: 1px solid blue; */

}

.imgEffectOff {

    width: 100%;
    height: 26em;

}

.imgSlider img {

    min-height: 100%;
    min-width: 100%;
    margin: auto;
    object-fit: cover;
    /* transform: translateX(0em);
    transition: transform 2s; */

}

.contentDet {

    width: 100%;
    height: 2.5em;
    background: rgba(232, 232, 232, 0.9);
    color: black;
    border-bottom: 1px solid black;
    margin: .3em auto;
    transition: height 1s;

}

.detHead:hover {

    cursor: pointer;

}

.detHead {

    display: flex;
    justify-content: space-between;
    align-items: center;

}

.detHead p {

    width: 95%;
    height: 2em;
    padding: .3em;
    font-weight: bold;

}

.iconArrow {

    transition: transform .5s;

}

.iconGiro {

    transform: rotate(180deg);

}

.detProd {

    width: 95%;
    height: 100%;
    margin: .5em auto;
    opacity: 0;
    transform: translateX(100em);

    transition: transform .3s, opacity .2s;

}

.detProd ul {

    margin: 0 0 1em 0;
    list-style: none;

}

.detProd ul li {

    margin: 0 0 .2em 0;

}

.detProd p {

    font-weight: bold;
    text-decoration: underline;
    margin: 1em 0;

}

.detList {

    height: 32em;

}

.detList p {

    text-decoration: underline;

}

.detList .detProd {

    transform: translateX(0em);
    opacity: 1;
    transition: transform .5s, opacity 2s;

}

/* prueba */

.containerListDes {

    color: #fff;

}

.item {

    background: red;

}

/* prueba */

/* ===== Responsive ===== */

/* @media screen and (min-width: 600px) {

    .cardProductContent {

        width: 70%;

    }

    .cardProductContent img {
    
        height: 20em;
    
    }
    
} */

@media screen and (min-width: 768px) {

    .headband {

        top: 3em;

        width: 17em;

    }

    .detList {

        height: 27em;

    }

    .btnVer,
    .btnCon {

        width: 35%;

    }

    .btnVer,
    .btnCon p {

        font-size: 1em;

    }

    .OfertaCardSubtitle2, .OfertaCarac {

        display: flex;
        justify-content: space-around;

    }

    .preNum, .preText {

        display: block;
    
        font-size: 1.5em;

    }

    .preText {

        grid-column: 1 / 2;

    }

    .preNum {

        grid-column: 2 / 3;

    }

    .OfertaCardSubtitle1,
    .OfertaCardSubtitle2, 
    .priceAdhe p {
    
        font-size: 1em;
    
    }

    .preNumCuot,
    .preTextCuot {
    
        font-size: 1.5em;
    
    }

}

@media screen and (min-width: 1400px) {

    /* .cardProductContent {
        width: 80%;
    }

    .cardProductContent img {
    
        height: 25em;
    
    }

    .Descuento {
        margin-left: 35%;
    }

    .OfertaCardTitle,
    .Descuento,
    .OfertaCardDesc {

        font-size: 1em;

    } */

    /* .preText {

        font-size: 1.2em;

    } */

    .detList {

        height: 23em;

    }

}

@media screen and (min-width: 1700px) {

    .headband {

        top: 2.5em;

    }

}

/* @media screen and (min-width: 1700px){

    .headband {
    
        left: 73%;
    
    }
    
} */