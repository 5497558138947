
.btnConstA {

    color: black;

}

.btnConstA p {

    text-align: center !important;

}

.btnConst {

    display: flex;
    flex-direction: column;
    justify-content: center;
    
    font-weight: bold;
    text-align: center;

    width: 15em;
    height: 2em;
    background-color: gainsboro;

}

.btnConstHijo1, 
.btnConstHijo2, 
.btnConstHijo3, 
.btnConstHijo4 {
    
    position: absolute;
    
    width: 15em;
    height: 2em;
    background-image: linear-gradient(red, red);
    background-repeat: no-repeat;
    transition: background-size .3s;
    /* border: 1px solid red; */

}

.btnConstHijo1 {

    background-position: 0% 100%;
    background-size: 0% 2px;

}

.btnConstHijo2 {

    background-position: 100% 0%;
    background-size: 0% 2px;

}

.btnConstHijo3 {

    background-position: 0% 0%;
    background-size: 2px 0%;

}

.btnConstHijo4 {

    background-position: 100% 100%;
    background-size: 2px 0%;

}

.btnConst:hover .btnConstHijo1, .btnConst:hover .btnConstHijo2 {

    background-size: 100% 2px;

}

.btnConst:hover .btnConstHijo3, .btnConst:hover .btnConstHijo4 {

    background-size: 2px 100%;

    cursor: pointer;

}