.AlertContainer {
    
    position: fixed;
    bottom: 50%;
    left: -100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    width: 80%;
    height: 10em;
    background: rgba(185, 0, 0, 1);
    color: #fff;
    padding: .5em;
    border-radius: .2em;
    text-align: center;
    transition: left .3s ease-out;

}

.AlertContainer h3 {

    margin: 0 0 0 1em;
    padding: 0 .7em 0 0;
    line-height: 1.7em;
    border-right: 1px solid red;

}

@keyframes borderAlert {

    from {

        background: rgb(88, 0, 0);

    }

    to {

        background: rgba(185, 0, 0, 1);

    }
    
}

.active {

    left: 10%;

}

.active .button {

    animation: borderAlert .7s infinite ease-out;

}

.button {

    width: 60%;
    height: 2em;
    text-align: center;
    padding: .3em;
    border: 1px solid red;
    border-radius: .2em;

}

.button:hover {

    cursor: pointer;
    background: rgba(230, 0, 0, 1);

}

.button p {

    font-weight: bold;

}

@media screen and (min-width: 600px) {

    .AlertContainer {

        bottom: 10%;

        height: 7em;

    }
    
}

@media screen and (min-width: 800px) {

    .AlertContainer {
        
        flex-direction: row;

        height: 3em;

    }

    .button {
    
        width: 15%;
        height: 2em;
        padding: .3em;
    
    }
    
}

@media screen and (min-width: 1000px) {

    .AlertContainer {
        
        width: 60%;

    }

    .active {
    
        left: 20%;
    
    }
    
}

@media screen and (min-width: 1300px) {

    .AlertContainer {
        
        width: 50%;

    }

    .active {
    
        left: 25%;
    
    }
    
}

@media screen and (min-width: 1500px) {

    .AlertContainer {
        
        width: 40%;

    }

    .active {
    
        left: 30%;
    
    }
    
}