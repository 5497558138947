.reservaCard {

    background: rgba(240, 240, 240, 0.8);
    border: 1px solid #999;
    border-radius: 0.5em;
    margin: 0.5em 0;

}

.reservaCard__contentTitle {

    display: grid;
    grid-template-columns: repeat(10, 1fr);

}

.contentTitle__Header {

    width: 95%;
    height: 2em;
    margin: 0 auto;
    grid-column: 1 / 10;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    border: 1px solid #999;
    border-radius: 0 0 0.5em 0.5em;
    border-top: 0;

}

.titleDetail__Switch {

    grid-column: 10 / 11;

}

.reservaCard__icon {

    width: 3%;
    height: 2.5em;
    margin: 0 auto;
    padding: 0;
    cursor: pointer;
    vertical-align: middle;

}

.reservaCard__content {

    display: none;

}

.reservaCard__contentAction {

    animation: deslizarActive 1.5s, opacityActive 2s;

}

@keyframes deslizarActive {

    from {

        display: none;
        max-height: 0;
        opacity: 0;

    }

    to {

        display: block;
        max-height: 10em;
        opacity: 1;

    }
    
}

@keyframes opacityActive {

    from {

        opacity: 0;

    }

    to {

        opacity: 1;

    }
    
}

.reservaCard__contentDisable {

    animation: deslizarDisable 1s, opacityDisable 0.8s;

}

@keyframes deslizarDisable {

    from {

        display: block;
        max-height: 10em;
        opacity: 1;

    }

    to {

        display: none;
        max-height: 0;
        opacity: 0;

    }
    
}

@keyframes opacityDisable {

    from {

        opacity: 1;

    }

    to {

        opacity: 0;

    }
    
}

.reservaCard__vis {

    margin: 0.5em 0 0 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 0.5em;

}

.reservaCard__datIzq {

    height: 2em;
    border: 1px solid #999;
    border-radius: 0 0.5em 0.5em 0;
    border-left: 0;

}

.reservaCard__dat {

    height: 2em;
    border: 1px solid #999;
    border-radius: 0.5em;

}

.reservaCard__datDer {

    height: 2em;
    border: 1px solid #999;
    border-radius: 0.5em 0 0 0.5em;
    border-right: 0;

}

.reservaCard__mess {

    width: 80%;
    margin: 0 auto;
    grid-column: 1 / 4;
    border: 1px solid #999;
    border-radius: 0.5em 0.5em 0 0;
    border-bottom: 0;

}