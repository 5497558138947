.CallActive1Content {

    display: flex;
    justify-content: space-between;

    /* width: 17em; */
    border: 3px solid;
    margin: 0 auto;
    padding: .5em;
    animation: colorActive 3s linear infinite;
    /* transition: all 1s; */

}

.CallActive1Content a {

    background-image: linear-gradient(red, red);
    background-position: 0% 100%;
    background-repeat: no-repeat;
    background-size: 0% 2px;
    transition: background-size .3s;

}

.boxSend {

    margin: 0 0 0 1em;

}

.CallActive1Content a, .CallActive1Content p, .boxSend {

    display: flex;
    flex-direction: column;
    justify-content: center;

}

.CallActive1Content a:hover, .CallActive1Content p:hover {

    background-size: 100% 2px;

}

.CallActive1Content:hover {

    /* background: rgba(255, 55, 55, 1); */
    cursor: pointer;

}

@keyframes colorActive {

    0% {
        border-color: rgba(255, 0, 0, 0.8);
    }

    25% {
        border-color: rgba(110, 3, 3, 0.8);
    }

    50% {
        border-color: rgba(255, 0, 0, 0.8);
    }

    75% {
        border-color: rgba(110, 3, 3, 0.8);
    }

    100% {
        border-color: rgba(255, 0, 0, 0.8);
    }

}

@media screen and (min-width: 768px) {

    .CallActive1Content {

        margin: 0;

    }
    
}