.bodyCard {

    height: 100%;
    transition: transform .5s;

}

.bodyCard:hover {

    transform: perspective(200px) rotateY(5deg);

    cursor: pointer;

}

.contentVideo {

    height: 100%;

    transition: filter .5s;

}

.contentVideo img {

    width: 100%;
    height: 100%;
    object-fit: cover;

}

.bodyCard:hover .contentVideo {

    filter: brightness(20%);

    cursor: pointer;

}

.contentText {

    position: relative;
    top: 0%;

    font-weight: bold;
    color: white;
    text-align: center;

    width: fit-content;
    margin: 0 auto;
    opacity: 0;
    transition: top .5s, opacity 1s;

}

.bodyCard:hover .contentText {

    top: -60%;

    opacity: 1;

    cursor: pointer;

}