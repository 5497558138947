.ContentGralServis {

    width: 90%;
    margin: 3em auto;

}

.ContentGralServis h2 {

    font-size: 1.5em;

}

.ServisContainer {

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    /* width: 95%; */
    margin: 3em auto;
    text-align: center;

}

.ServisContainer img {

    width: 30%;
    margin: 0 auto;

}

.btnActionServ {

    display: flex;
    justify-content: flex-start;

}

@media screen and (min-width: 768px) {

    .ContentGralServis h2 {

        font-size: 2em;

    }

}

@media screen and (min-width: 1024px) {

    .ServisContainer {
    
        flex-direction: row;
    
    }

}