.contentListGroup {

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    height: 30em;

}

.ContentCard {

    display: flex;
    flex-direction: column;
    justify-content: space-around;

    width: 100%;
    height: 8em;
    text-align: center;
    border: 1px solid black;
    /* padding: 1em; */
    background: rgb(255, 255, 255);
    color: #000;

    z-index: 100;

}

.ContentCard:hover {

    cursor: pointer;
    /* background-color: red; */
    border: 3px solid brown;

}

.contentImgBack {

    position: relative;

    height: 100%;
    filter: brightness(70%);

}

.contentImgBack img {

    width: 100%;
    height: 100%;
    object-fit: cover;

}

.contentTextLink {
    /* position: absolute; */
    /* top: 2em; */

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    
    height: 100%;
    padding: 0 .5em;
    /* width: 15em; */
    /* border: 1px solid red; */

}

.contentTextLink p, .contentTextLink h3 {

    /* color: #fff; */

}

.contentTextLink p {

    font-size: .7em;

}

.animation1 {

    animation: agrandar 3s;

}

.animation2 {

    animation: agrandar 3s 1s;

}

.animation3 {

    animation: agrandar 3s 2s;

}

@keyframes agrandar {

    0% {

        transform: scale(1);
        background: rgb(255, 255, 255);

    }

    50% {

        transform: scale(1.07);
        /* background: rgb(206, 206, 206); */
        /* background: rgb(255, 48, 48); */
        background: rgb(230, 230, 230);

    }

    100% {

        transform: scale(1);
        background: rgb(255, 255, 255);

    }

}

@media screen and (min-width: 768px) {

    .contentListGroup {

        /* flex-direction: row; */
        display: grid;
        grid-template-columns: repeat(3, 1fr);

        height: 0em;
        width: 90%;
        margin: 0 auto;

    }

    .ContentCard {

        width: 90%;
        height: 10em;
        margin: 0 auto;

    }

}