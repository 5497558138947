.CreatePubContent {

    width: 100%;
    height: 100vw;
    background: url(../../../images/fondos/20-12\ FONDO\ WEB-02.jpg) no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    border: 1px solid #000000;
    text-align: center;

}

.CreatePubContent > h1 {

    width: 80%;
    background: linear-gradient(90deg, rgba(2,0,36,0) 0%, rgba(255,255,255,1) 50%, rgba(0,212,255,0) 100%);
    margin: 1.5em auto 1em;
    font-size: 2.5em;

}