
.contentBaner {
    
    position: sticky;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    text-align: center;

    height: 100%;
    align-items: center;

    z-index: 1000;

}

.imgBanner {

    width: 100%;
    height: 30em;

}

.imgBanner img {

    width: 100%;
    height: 100%;
    object-fit: cover;

}

.contentBaner h2 {

    position: absolute;

    font-size: 1.5em;
    color: white;

}

.containerConstCont {
    
    position: relative;
    top: 20em;
    
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-around;

    height: 100%;
    margin: 2em 0;
    opacity: 0;
    transition: top 1s, opacity 1.5s;

}

.containerConstContActive {

    top: 0;

    opacity: 1;

}

.contImg {
    
    width: 20em;
    height: 20em;
    margin: auto;
    border: 1px solid black;

}

.contImg img {

    width: 100%;
    height: 100%;
    object-fit: cover;

}

.constConteText {
    
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    width: 100%;
    height: 30em;
    padding: 1em;
    margin: auto;
    /* border: 1px solid; */

}

.constConteText h1 {

    font-size: 1.5em;

}

.constConteText p {

    text-align: justify; 

}

/* .contBtnAc {
    
    display: flex;
    justify-content: flex-end;
    
} */

.contVentajas {

    position: relative;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    height: 57em;
    background-color: black;

    z-index: 100;

}

.contImgVent {

    position: relative;
    left: -100%;
    
    width: 90%;
    height: 20em;
    margin: 1em auto;
    background: white;

}

.contImgVent img {

    width: 100%;
    height: 100%;
    object-fit: cover;

}

.contVenText {

    position: relative;
    left: 100%;

    color: white;
    
    width: 90%;
    height: 30em;
    margin: 0 auto;
    /* border: 1px solid white; */

}

.contImgVent, .contVenText {

    transition: left 1s;

}

.contImgVentActive, .contVenTextActive {

    left: 0;

}

.contVenText h3 {

    font-size: 2em;

}

.contListVent {
    
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    height: 25em;
    margin: 1em 0;

}

.contListVent li {

    display: flex;
    justify-content: space-between;

    text-align: justify;

}

.iconTem {
    
    width: 3em;
    /* height: 2em; */
    /* border: 1px solid white; */
    
}

.iconTem img {

    width: 100%;

}

.contTextVent {

    width: 80%;

}

.contTextVent h3 {

    font-size: 1em;

}

.contTextVent p {

    font-size: .8em;
    
}

.contConstImp {
    
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}

.constText {

    position: relative;
    left: 100%;
    
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    text-align: justify;

    width: 90%;
    height: 45em;
    padding: 1em;

}

.btnConsText {
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    font-weight: bold;
    text-align: center;

    width: 10em;
    height: 2em;
    background-color: gainsboro;

}

.contListConst {

    position: relative;
    left: -100%;

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: .5em;

    width: 100%;
    height: 35em;
    padding: .5em;

}

.constText, .contListConst {

    transition: left 1s;

}

.constTextActive, .contListConstActive {

    left: 0;

}

.contListConst > div {

    /* height: 10em; */
    background-color: white;
    padding: 0.5em;
    border: 2px solid;

}

.iconTem2 {
    
    width: 4em;
    height: 4em;
    /* background-color: brown; */

}

.contConstText {
    
    
    margin: 1em 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 5em;
}

.contConstText h3 {

    font-size: 1em;

}

.contConstText p {

    font-size: .9em;
    text-align: justify;

}

@media screen and (min-width: 768px) {

    .contentBaner h2 {
    
        font-size: 3em;
    
    }

    .containerConstCont {
        
        flex-direction: row-reverse;
    
        height: 25em;
        margin: 5em 0;
    
    }

    .constConteText {

        justify-content: space-around;

        width: 50%;
        height: auto;
        margin: 0;
    
    }

    .contVentajas {
    
        flex-direction: row;
        justify-content: space-evenly;
        
        height: 37em;
    
    }

    .contImgVent {

        height: 25em;

    }

    .contImgVent, .contVenText {
            
        width: 45%;    
        margin: auto 0;
    
    }

    .contListVent {
            
        height: 25em;
        margin: .5em 0;
    
    }

    .iconTem {
        
        width: 4em;
        height: 4em;
        /* border: 1px solid white; */
        
    }

    .contTextVent h3 {
    
        font-size: 1.1em;
    
    }

    .contConstImp {
        
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        margin: 5em 0;
    
    }

    .constText {
    
        width: 50%;
        height: 25em;
        margin: auto 0;
    
    }

    .contListConst {
    
        grid-gap: 1em;
    
        width: 50%;
        height: 80%;
        padding: 1em;
        margin: auto 0;
    
    }

    .contListConst > div {
    
        height: 100%;
    
    }

    .contConstText {
        
        justify-content: start;

        height: auto;
        margin: 0;
        margin-top: .5em;
        
    }

    .contImg {
        
        width: 40%;
        margin: auto 1em;
    
    }
    
}

/* @media screen and (min-width: 1024px) {

    .contImg {
        
        width: 50%;
        margin: auto 1em;
    
    }
    
} */