.ConsultasContent {

    width: 100%;
    background: url(../../../images/fondos/20-12\ FONDO\ WEB-02.jpg) no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    border: 1px solid #000000;
    text-align: center;

}

.arrowContent {
    width: 50%;
    height: 3em;
    background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%);
    /* display: grid;
    grid-template-columns: repeat(2, 1fr); */
    margin: .5em auto;
    padding: .5em 0 0 0;
    display: flex;
    justify-content: space-around;

}

.arrowRight, .arrowLeft {

    width: 4% !important;
    height: 3.2em;
    /* border: 1px solid #000000; */

}

.arrowRight:hover, .arrowLeft:hover {

    cursor: pointer;

}

@media screen and (max-width: 1000px ) {

    .arrowContent {

        width: 60%;
    
    }
    
}

@media screen and (max-width: 500px ) {

    .arrowContent {

        width: 80%;
    
    }
    
}