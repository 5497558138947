.contenedor {
    color: #000000;
    text-align: center;
}

.cartel {
    width: 5em;
    height: 5em;
    padding: 10px 0px 5px 0px;
    text-align: center;
    display: inline-block;
    margin: 5px;
    border: 3px solid #000000;
    border-radius: 5px;

    background: linear-gradient(45deg, #FFF100, #FE5206 );
    color: black;
}

.cartel>div {
    font-size: 2em;
    /* font-family: 'Chela One', sans serif; */
    font-family: 'Times New Roman', 'bodoni', Times, serif;
    font-weight: bold;
    animation: fade 3s;
    line-height: 30px;
    /* margin-top: 5px; */
}

.cartel>div>h3 {
    /* margin-top: 15px; */
    font-size: .5em;
    font-weight: normal;
}

.h3 {
    bottom: 0;
    margin: 0 auto;
}

.contenedor {
    margin-top: 2em;
}

.contenido {
    width: 80%;
    margin: 0 auto;
    border: 0px solid black;
    z-index: 10;
}

/* li {
    display: inline-block;
    font-size: 1.5em;
    list-style-type: none;
    padding: 1em;
    text-transform: uppercase;
}

li span {
    display: block;
    font-size: 4.5rem;
} */

.numeros {
    font-family: 'Chela one', sans-serif;
    font-size: 60px;
    transition: .3s;
    animation-name: fade;
    animation-duration: 3s;
}

.responsivo1 {
    display: inline-block
}

.responsivo2 {
    display: inline-block
}

@media (max-width: 450px) {
    .contenedor {
        margin-top: 15%;
    }

    .banner {
        margin-bottom: 30px;
    }

    .cartel {
        height: 99px;
        width: 99px;
    }

    .cartel>div {
        font-size: 45px;
    }

    .cartel>div>h3 {
        font-size: 15px;
        margin-top: 0px;
    }
}

@keyframes fade {
    0% {
        opacity: 0;
    }

    30% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}