.ConsultasContent {

    width: 80%;
    background-color: rgba(255, 255, 255, 0.8);
    margin: 1em auto;
    border: 1px solid rgb(192, 192, 192);
    border-radius: 1em;

}

.contentHeat {

    width: 95%;
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    grid-gap: 2em;
    border: 1px solid rgb(192, 192, 192);
    border-radius: 0 0 1em 1em;
    border-top: 0;
    margin: 0 auto;
    padding: .5em 0;
    vertical-align: middle;

}

.contentHeat > h1 {

    width: 100%;
    margin: 0  0 0 7%;
    grid-column: 1 / 6;
    font-size: 1.5em;

}

.fecha {

    width: 100%;
    grid-column: 6 / 10;

}

.email, .phone {
    
    font-size: 1.3em;

}

.consultasCard__icon {

    width: 5%;
    margin:  0 auto;
    grid-column: 1 / 11;

}

.consultasCard__icon :hover {

    cursor: pointer;

}

.ConsultaCard__content {

    display: none;
    grid-column: 1 / 11;

}

.ConsultaCard__contentAction {

    grid-column: 1 / 11;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: .5em;
    animation: deslizarActive 1.5s, opacityActive 2s;

}

.ConsultaCard__contentAction > p {

    grid-column: 1 / 3;
    padding: 0 .5em;

}

@keyframes deslizarActive {

    from {

        display: none;
        max-height: 0;
        opacity: 0;

    }

    to {

        display: block;
        max-height: 10em;
        opacity: 1;

    }
    
}

@keyframes opacityActive {

    from {

        opacity: 0;

    }

    to {

        opacity: 1;

    }
    
}

.consultaCard__contentDisable {

    grid-column: 1 / 11;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: .5em;
    animation: deslizarDisable 1s, opacityDisable 0.8s;

}

.ConsultaCard__contentMess {

    grid-column: 1 / 3;

}

@keyframes deslizarDisable {

    from {

        display: block;
        max-height: 10em;
        opacity: 1;

    }

    to {

        display: none;
        max-height: 0;
        opacity: 0;

    }
    
}

@keyframes opacityDisable {

    from {

        opacity: 1;

    }

    to {

        opacity: 0;

    }
    
}

.ConsultaCard__contentIzq {

    width: 80%;
    height: 2.5em;
    border-radius: .5em;
    border: 1px solid rgb(192, 192, 192);
    margin: 0 auto;
    padding: .5em 0;

}

.ConsultaCard__contentDer {

    width: 80%;
    height: 2.5em;
    border-radius: .5em;
    border: 1px solid rgb(192, 192, 192);
    margin: 0 auto;
    padding: .5em 0;

}

.ConsultaCard__contentMess {

    width: 80%;
    border-radius: .5em .5em 0 0;
    border: 1px solid rgb(192, 192, 192);
    border-bottom: 0;
    margin: 1em auto 0;
    padding: .5em;

}

@media screen and (max-width: 1200px) {

    .contentHeat > h1 {

        grid-column: 1 / 5;
        font-size: 1.3em;
    
    }
    
    .fecha {

        grid-column: 5 / 9;
    
    }

    .titleDetail__Switch {

        grid-column: 9 / 11;

    }
    
}

@media screen and (max-width: 900px) {

    .contentHeat > h1 {

        font-size: 1em;
    
    }
    
    .fecha {
    
        font-size: 1em;
    
    }

    .ConsultaCard__contentIzq, .ConsultaCard__contentDer {

        font-size: .9em;
    
    }

    .ConsultaCard__contentMess > p {

        font-size: .8em;
    
    }
    
}

@media screen and (max-width: 700px) {

    .contentHeat > h1 {

        grid-column: 1 / 6;
        font-size: 1em;
    
    }
    
    .fecha {
    
        width: 100%;
        grid-column: 6 / 9;
        font-size: 1em;
    
    }

    .ConsultaCard__contentIzq {

        font-size: .8em;
    
    }
    
}