.benContainer {

    /* width: 90%; */
    margin: auto;
    padding: .5em 0;
    background: rgba(0, 0, 0, 1);
    color: white;

}

.benContainer h2 {

    width: 97%;
    margin: 0 auto;
    font-size: 1.2em;

}

.bodyGroup {

    width: 95%;
    margin: 0 auto 4em;

}

.content {

    /* display: flex;
    flex-direction: column;
    justify-content: space-between; */
    
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-gap: 0.5em;

    /* width: 90%; */
    margin: 2em auto;

}

.cardBox {

    float: left;
    font-size: 1em;
    margin-bottom: 1em;
    perspective: 800px;
    transition: all 0.3s ease 0s;
    /* width: 23.7%; */
    width: 100%;

}

.cardBox:hover .card {
    transform: rotateY(180deg);
    cursor: pointer;
}

.card {
    background: rgba(0, 0, 0, 1);
    cursor: default;
    height: 200px;
    transform-style: preserve-3d;
    transition: transform 0.4s ease 0s;
    width: 100%;
    -webkit-animation: giro 1s 1;
    animation: giro 1s 1;
    border: 1px solid white;
}

.card p {

    font-size: 1em;
}

.card .front,
.card .back {

    display: flex;
    flex-direction: column;
    justify-content: center;

    backface-visibility: hidden;
    box-sizing: border-box;
    color: white;
    font-size: 1em;
    height: 100%;
    padding: .3em;
    position: absolute;
    text-align: center;
    width: 100%;
}

.back p, .front p {

    text-align: center;

}

.card .front strong {
    background: #fff;
    border-radius: 100%;
    color: #000;
    font-size: 1.5em;
    line-height: 30px;
    padding: 0 7px 4px 6px;
}

.card .back {
    transform: rotateY(180deg);
}

.card .back a {
    padding: 0.3em 0.5em;
    background: #000;
    color: #fff;
    text-decoration: none;
    border-radius: 1px;
    font-size: 0.9em;
    transition: all 0.2s ease 0s;
}

.card .back a:hover {
    background: #fff;
    color: #000;
    text-shadow: 0 0 1px #000;
}


@-webkit-keyframes giro {
    from {
        transform: rotateY(180deg);
    }

    to {
        transform: rotateY(0deg);
    }
}

@keyframes giro {
    from {
        transform: rotateY(180deg);
    }

    to {
        transform: rotateY(0deg);
    }
}

.btnAction {

    display: flex;
    justify-content: flex-end;

    margin: 2em 0;

}

@media screen and (min-width: 768px) {

    .content {
    
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 1em;
    
    }

    .cardBox {

        margin-bottom: 0;
    }
    
}

@media screen and (min-width: 1024px) {

    .benContainer h2 {
    
        font-size: 2em;
    
    }

    .content {
    
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
    
    }

    .card .front,
    .card .back {

        padding: .5em;

    }

    .btnAction {
    
        display: flex;
        justify-content: flex-end;
    
        margin: 2em 0;
    
    }
    
}