.containerFichTec h2 {

    text-align: center;
    font-size: 1.5em
}

.bodyFichTec {

    display: grid;
    grid-template-columns: repeat(1, 1fr);

    width: 90%;
    margin: 3em auto;
    /* padding: 3em 0; */

}

.contentImgFamily {

    text-align: center;

    /* border: 1px solid red; */

}

.contentImgFamily h3 {

    margin: 0 auto;

}

.contentImgFamily h3 {

    display: inline-block;

}

.boxImgFich {

    width: 100%;

    /* border: 1px solid red; */

}

.boxImgFich {

    height: 17em;
    margin: 3em auto 0;

}

.contFichText {

    display: flex;
    flex-direction: column;

    width: 100%;
    height: 50em;
    margin: 2em auto 0;
    /* border: 1px solid white; */
    background: rgba(0, 0, 0, 1);
    color: #fff;

}

.contListName ul,
.txtProduct ul {

    list-style: none;

}

.contListName ul {

    display: flex;
    justify-content: space-around;

}

.contListName ul li {

    display: flex;
    flex-direction: column;
    justify-content: center;

    width: 100%;
    height: 2.5em;
    border-left: 2px solid black;

}

.contListName ul li:hover {

    background: rgb(187, 0, 0);
    color: white;

    cursor: pointer;


}

.contListNameActive {

    background: rgb(187, 0, 0);
    color: white;

}

.contListName ul li p {

    font-weight: bold;
    transition: transform .7s;

}

.contListName ul li:hover p {

    transform: scale(1.2);

}

.contentImgExt,
.contentImgInt,
.contentImgPla {

    position: absolute;

    /* display: none; */

    width: 90%;
    /* height: 17.5em; */
    overflow: hidden;
    opacity: 0;
    transition: opacity 1s;

}

.contArrowL,
.contArrowR {

    position: absolute;

    width: 5em;
    height: 100%;
    transition: background .5s;

    z-index: 100;

}

.contArrowR {

    top: 0;
    right: 0;

}

.arrowLeft,
.arrowRigth {

    position: absolute;
    top: 30%;

}

.contArrowL:hover,
.contArrowR:hover {

    background: rgba(255, 255, 255, .3);

    cursor: pointer;

}

.arrowLeft {

    left: 1em;

    transform: rotate(31deg);

    z-index: 100;

}

.arrowRigth {

    right: 1em;

    transform: rotate(-31deg);

    z-index: 1000;

}

.imgCarrousell {

    position: relative;
    left: 0;

    display: flex;

    /* height: 25em; */
    transition: left 1s;

}

.imgCarrousell img,
.contentImgInt img,
.contentImgPla img {

    height: 100%;
    width: 100%;
    object-fit: cover;

}

.active {

    /* display: block; */

    opacity: 1;

}

.contFichTec {

    width: 95%;
    margin: 2em auto;

}

.contTextList {

    width: 95%;
    margin: .5em 0 0 .5em;

}

.contTextList ul {

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0.5em;

}

.contTextList ul li {

    display: flex;
    flex-direction: column;
    justify-content: center;

    height: 3em;
    border-bottom: 2px solid white;

    list-style: none;

}

.contTextList ul li:hover {

    font-weight: bold;

    background: gainsboro;
    color: black;

    cursor: pointer;

}

.contTextListActive {

    font-weight: bold;

    background: gainsboro;
    color: black;
    border: 3px solid rgb(187, 0, 0);

}

.contTextList ul li p {

    margin: 0 auto;

}

.contDescFich {

    width: 80%;

}

.txtProduct {

    position: absolute;
    right: -100%;

    width: 85%;
    margin: 2em 0;
    transition: right 1s;

}

.txtProduct ul li {

    margin: 1em 0;

}

.txtProductActive {

    right: 7%;

    /* display: block; */

}

.contDescProd {

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    height: 10em;

}

.contDescProd p {

    text-align: justify;

    font-size: .9em;

}

.priceSuscrip {

    margin: 1em 0;

}

.txtTachado {

    text-decoration: line-through;

}

.contentCuot {

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 13em;
    /* margin-top: 1em; */

}

.contentCuot a {

    color: black;

}

.cantCuot {

    display: flex;

}

.cantCuot p {

    font-size: 1.5em;
    font-weight: bold;

    margin: auto;

}

.btnFinan {

    display: flex;
    flex-direction: column;
    justify-content: center;

    /* background-color: gainsboro; */
    text-align: center;
    height: 3em;

    animation: animateBtn infinite 1.5s linear;

}

@keyframes animateBtn {

    0% {

        border: 5px solid #8E0000;

    }

    50% {

        border: 5px solid #FF0000;

    }

    100% {

        border: 5px solid #8E0000;

    }

}

/* .btnFinan h3 {

    display: flex;
    flex-direction: column;
    justify-content: center;

    width: 97%;
    height: 80%;
    margin: auto;
    background-color: gainsboro;

} */

.containerBtn {

    display: flex;
    flex-direction: column;
    justify-content: center;

    height: 7em;
    border-top: 1px solid black;
    border-bottom: 1px solid black;

}

.contBtnPago {

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    height: 4em;
    margin: 1em auto;
    text-align: center;

}

.contBtnPago h3 {

    display: flex;
    flex-direction: column;
    justify-content: center;

    height: 2em;

}

.contBtnPago a {

    width: 80%;
    margin: 0 auto;

}

.btnLinkPago {

    display: flex;
    flex-direction: column;
    justify-content: center;

    font-weight: bold;

    background-color: gainsboro;
    color: black;
    /* border: 1px solid red; */

}

.btnLinkPago:hover {

    background: rgba(182, 0, 0, 0.9);
    color: white;

}

.btnLinkPago {

    height: 3em;

}

@media screen and (min-width: 768px) {

    .containerFichTec h2 {

        font-size: 2em
    }

    .boxImgFich {

        height: 30em;

    }

    .contFichText {

        height: 30em;

    }

    .contTextList {

        width: 100%
    }

    .contTextList ul {

        grid-template-columns: repeat(5, 1fr);

    }

    .contTextList ul li {

        width: 90%;

    }

    .btnFinan {

        width: 50%;
        margin: 0 auto;

    }

}

@media screen and (min-width: 1024px) {

    .containerFichTec h2 {

        margin-top: 2em;

    }

    .bodyFichTec {

        grid-template-columns: repeat(2, 1fr);

    }

    .contentImgExt,
    .contentImgInt,
    .contentImgPla {

        width: 45%;
        /* height: 17.5em; */

    }

    .contFichText {

        flex-direction: row;

        height: 47em;

    }

    .contTextList {

        width: 20%;

    }

    .contTextList ul {

        display: flex;
        flex-direction: column;

    }

    .contFichText ul li {

        width: 100%;

    }

    .boxImgFich {

        height: 20em;

    }

    .txtProduct {

        width: 65%;

    }

    .contFichTec {

        width: 90%;

    }

    .contentPrice {

        padding: 0 2em;

    }

    .contBtnPago {

        flex-direction: row;
        justify-content: space-around;

        width: 80%;
        height: 3em;

    }

    .contBtnPago a {

        width: 25em;
        margin: 0;

    }

}

.contentVideo {

    width: 80%;
    margin: 0 auto;

}

.contentVideo video {

    width: 100%;

}

@media screen and (min-width: 1200px) {

    .contFichTec {

        margin-top: 7em;

    }

}

@media screen and (min-width: 1500px) {

    .contFichTec {

        margin-top: 15em;

    }

}