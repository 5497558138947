
.titleStyle {

    font-size: 2em;
    text-align: center;

    margin: 2em 0;

}

.contentEnt {

    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 2em;

    width: 95%;
    margin: 3em auto;

}

@media screen and (min-width: 768px) {

    .contentEnt {

        grid-template-columns: repeat(2, 1fr);

    }
    
}

@media screen and (min-width: 1024px) {

    .contentEnt {

        grid-template-columns: repeat(3, 1fr);

        width: 90%;

    }
    
}

@media screen and (min-width: 1440px) {

    .contentEnt {

        width: 80%;

    }
    
}