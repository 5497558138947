.CreateProduts {

    width: 100%;
    height: 100vw;
    background: url(../../../images/fondos/20-12\ FONDO\ WEB-02.jpg) no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    border: 1px solid #000000;
    text-align: center;
    padding: 1em;

}

.CreateProduts > h1 {

    background: linear-gradient(90deg, rgba(255,255,255,0.5) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,0.5) 100%);

}

.CreateProduts > form {

    width: 90%;
    margin: 1em auto;

}

.CreateProduts > form > input {

    margin: .5em auto;

}

.CreateProduts > form > input[type="text"] {

    background: rgba(255,255,255,0.9);

}