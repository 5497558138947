
.IndexAdmContent {

    background: url(../../../images/fondos/20-12\ FONDO\ WEB-02.jpg) no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    border: 1px solid #000000;
    min-height: 100vh;

}

.contentGraphics {

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1em;
    margin: 2em 0;

}

.boxGraphics {

    width: 90%;
    background: linear-gradient(to bottom, rgba(31, 31, 31, 0.8), rgba(0, 0, 0, 0.95) 70%);
    border: 1px solid rgb(255, 0, 0);
    border-radius: 1em;
    margin: 0 auto;

}

.boxGraphics > h1 {

    width: 50%;
    margin: 0 auto;
    padding: .1em 0;
    font-family: 'Noto Sans', sans-serif;
    font-weight: 100;
    border-bottom: 2px solid red;
    color: #fff;
    text-align: center;

}

@media screen and (max-width: 1500px) {

    .boxGraphics > h1 {

        font-size: 1.6em

    }
    
}

@media screen and (max-width: 1200px) {

    .boxGraphics > h1 {

        font-size: 1.3em

    }
    
}

@media screen and (max-width: 1000px) {

    .boxGraphics > h1 {

        font-size: 1em

    }
    
}

@media screen and (max-width: 800px) {

    .contentGraphics {

        grid-template-columns: repeat(1, 1fr);

    }

    .boxGraphics > h1 {

        width: 70%;
        font-size: 1.7em

    }
    
}

@media screen and (max-width: 500px) {

    .boxGraphics > h1 {

        font-size: 1.3em

    }
    
}