.cardContent {

    /* Positioning */

    /* Box-model */
    width: 60%;
    height: 16em;
    margin: .5em auto;

    /* Typography */

    /* Visual */
    background: linear-gradient(30deg, rgba(216, 216, 216, 0.9), rgba(255, 255, 255, 0.9));
    border: 1px solid #000;
    box-shadow: 5px 5px 10px;

    /* Misc */

}

.titleHead {

    display: flex;
    justify-content: space-evenly;
    padding: .2em 0 0 0;

}

.headCard {
    
    /* background: rgba(255, 255, 255, .5); */
    display: flex;
    justify-content: space-evenly;
    margin: .5em 0;
    
}

.cardContent h2, .headCard h3 {

    font-family: 'Tahoma';
    font-weight: 100;

}

.sliderContent {

    width: 70%;
    height: 55%;
    display: flex;
    align-items: center;
    margin: 0 auto;

}

.slider {

    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-evenly;

}

.slider img {

    position: absolute;
    width: 21%;
    height: 14.5%;
    transition: all 2s;
    object-fit: cover;
    opacity: 0;

}

/* .slider img:nth-child(2) {

    opacity: 1;
    transition: all 2s;

} */

.active {

    opacity: 1;
    transition: all 2s;

}

.back, .next {

    /* Positioning */
    position: relative;
    z-index: 3;

    /* Box-model */
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
    height: 100%;
    margin: 0 auto;

    /* Typography */
    font-size: 2.5em;

    /* Visual */
    background: rgba(255, 255, 255, 0.3);

    /* Misc */

}

.back:hover, .next:hover {

    cursor: pointer;

}

.back {

    left: -15%;

}

.next {

    right: -15%;

}