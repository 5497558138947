.cardContent {

    position: relative;
    top: 0;

    filter: brightness(100%);

    transition: top .3s, filter .5s;

}

.cardContent:hover {

    top: -1em;

    filter: brightness(60%);

}

/* .cardContentProx {

    content: "Proximamente";

} */

.EntProx {

    /* visibility: hidden; */

    position: absolute;

    width: 100%;
    background:rgba(0, 0, 0, .8);
    color: #fff;
    padding: .5em;

}

.imgCard img {

    width: 100%;
    height: 15em;
    object-fit: cover;

}

.contentText {

    position: absolute;
    bottom: 0.5em;

    color: white;
    width: 100%;
    background: rgba(0, 0, 0, .5);
    padding: 0.5em;

}

.contentText h3 {

    font-size: 1em;

}