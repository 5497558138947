
/* temporal */

.boxVideo {

    border: 1px solid;
    width: 80%;
    height: 15em;
    margin: auto;

}

/* temporal */

.contBannerPago {

    display: flex;
    flex-direction: column;
    justify-content: center;

    height: 10em;
    text-align: center;
    border: 1px solid black;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);

    /* background-color: red; */

    animation: backgroundAinimated 10s infinite;

        clip-path: polygon(
            100% 0, 0 0, 
            0% 86%, 7% 75%, 
            13% 68%, 21% 65%, 
            29% 67%, 38% 72%, 
            45% 78%, 51% 84%, 
            57% 89%, 64% 94%, 
            72% 95%, 81% 92%, 
            88% 85%, 95% 73%, 
            100% 61%);

}

.contBannerPago h3 {

    font-size: 2em;

}

@keyframes backgroundAinimated {

    0% {

        background: rgba(255, 255, 255, .9);
        color: #000;

    }

    25% {

        background: rgba(177, 177, 177, 0.9);
        color: #fff;

    }

    50% {

        background: rgba(185, 47, 47, 0.9);
        
    }

    75% {

        background: rgba(177, 177, 177, 0.9);
        color: #fff;
        
    }

    100% {

        background: rgba(255, 255, 255, .9);
        color: #000;
        
    }
    
}

.contVideo {
    
    display: flex;
    flex-direction: column;

    height: 25em;
    padding: 2em 0;
    text-align: center;
    
}

.contentActionBtn {
    
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    height: 5em;
    border-top: 1px solid;
    border-bottom: 1px solid;

}

.contentActionBtn .textDer, 
.contentActionBtn .textIzq {

    margin: 0 auto;

}

.textIzq, .textDer {

    width: 80%;
    text-align: center;

}

.textDer {

    background:rgba(192, 192, 192, 0.9);
    border: 1px solid black;

}

.textDer a {
    
    color: black;

}

.textDer:hover {

    cursor: pointer;
    background: rgba(182, 0, 0, 0.9);

}

.textDer a:hover {
    
    color: white;
    
}

.contentProd {
    
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 2em;

    margin: 5em 0;

}

.boxImg {
    
    width: 90%;
    margin: 0 auto;

}

.boxImg img {

    width: 100%;

}

.boxDetailPago {
    
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    width: 90%;
    height: 25em;
    margin: 0 auto;

}

.boxDetailPago h3 {

    font-size: 1em;

}

.txtTachado {
    
    text-decoration: line-through;

}

.boxRadius {

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    background: rgba(204, 204, 204, 0.9);
    padding: .5em;

}

.contRadius {

    margin: 0 auto;

}

.contPriceProd {

    display: flex;
    flex-direction: column;
    justify-content: center;

    font-weight: bold;

    width: 100%;
    text-align: center;

}

.contPriceProd p {

    font-size: 1.7em;

}

.contListPrice {
    
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    height: 6em;

    /* transform: ; */

}

@media screen and (min-width: 1024px) {

    .contentActionBtn {
        
        flex-direction: row;
    
    }

    .contentActionBtn .textDer, 
    .contentActionBtn .textIzq {
    
        margin: auto 0;
    
    }
    
    .textDer {
    
        width: 20em;
    
    }

    .textIzq {

        width: 25em;

    }

    .contentProd {
        
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 1em;
    
    }

    .boxImg {
        
        width: 80%;
        margin: 0 auto;
    
    }

    .boxDetailPago {
        
        width: 70%;
        height: 20em;
    
    }

    .boxRadius {
    
        flex-direction: row;

        width: 25em;
    
    }

    .contRadius {
    
        margin: 0 1em;
    
    }

    .contPriceProd {
    
        width: 50%;
    
    }
    
}