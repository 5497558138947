.BannerSlide {
    overflow: hidden;
    transition: .3s ease all;
    /* z-index: 10; */
    height: 100%;
    min-width: 100%;
    position: relative;
}

/* .BannerSlide img {

    width: 100%;
    height: 20em;
    vertical-align: top;
    object-fit: cover;
    filter: brightness(70%);

    z-index: 0;
} */

.imgStyle {

    
    width: 100%;
    height: 20em;
    vertical-align: top;
    object-fit: cover;
    filter: brightness(70%);

    z-index: 0;

}

.imgStyle2 {
    
    width: 100%;
    height: 20em;
    vertical-align: top;
    object-fit: cover;

    z-index: 0;

}

.imgStyleFilterOff {

    filter: brightness(100%);
    
}

/* .imgStyleDiferent {

    filter: brightness(100%);

} */

.cardContent {

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    position: absolute;
    top: 50%;

    width: 95%;
    height: 7em;
    /* background: rgba(232, 232, 232, 0.7); */
    /* border: 1px solid yellow; */
    color: white;
    margin: 0 0 0 1em;
    padding: .5em;
    /* transform: translateX(-50%);
    transition: transform .5s; */
    /* animation: arrastreX .5s; */

    z-index: 10;

}

@keyframes arrastreX {
    /*todavia no esta aplicado*/

    from {

        /* transform: translateX(-50%); */
        background: red;

    }

    to {

        /* transform: translateX(0%); */
        background: black;

    }

}

.cardContent h1 {

    /* width: 40%; */
    font-size: .9em;
    /* border: 1px solid red; */

}

.cardContent h3 {

    font-size: .9em;

}

.buttom {

    position: relative;
    justify-content: flex-start;
    /* left: 60%; */

    width: 50%;
    background: rgba(0, 0, 0, 0.8);
    color: #fff;
    border: 1px solid #000000;
    text-align: center;
    /* margin: .5em 0 0 0; */
    padding: .3em 0;

}

.buttom:hover {

    background: rgba(232, 232, 232, 0.8);
    color: #000000;

    cursor: pointer;

}

.boxAdi {

    position: absolute;
    top: 0;

    display: flex;

    /* margin-top: 10em; */
    /* border: 1px solid red; */

}

.firstBox img, .secondBox img {

    width: 100%;
    height: 20em;

}

.firstBox {

    width: 60%;
    height: 20em;

}

.secondBox {

    width: 40%;

}

@media screen and (min-width: 500px) {

    .boxAdi {
    
        top: 0em;
    
    }

}

/* @media screen and (min-width: 768px) {

    .buttom {

        width: 30%;

    }

} */

@media screen and (min-width: 800px) {

    .BannerSlide img {

        height: 100%;

    }

    .cardContent {

        top: 60%;

    }

    .cardContent h1 {

        font-size: 1.3em;

    }

    .cardContent h3 {

        font-size: 1.2em;

    }

    .buttom {

        width: 25%;

    }

    .boxAdi {
    
        top: 10em;
    
    }

}

@media screen and (min-width: 1000px) {

    .buttom {

        /* top: 1em;
        left: 40%; */

        /* width: 30%; */

    }

    .cardContent {

        top: 60%;

        width: 80%;
        height: 9em;
        margin: 0 0 0 3em;

    }

    .cardContent h1 {

        font-size: 1.5em;

    }

    .cardContent h3 {

        font-size: 1.2em;

    }

}

@media screen and (min-width: 1200px) {

    .cardContent {

        width: 60%;

    }

    .buttom {

        /* left: 30%; */

        /* width: 30%; */

    }

}

@media screen and (min-width: 1501px) {

    .cardContent {

        top: 65%;

        /* width: 50%; */
        height: 10em;

    }

    .buttom {

        /* left: 30%; */

        /* width: 30%; */

    }

}