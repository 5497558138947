
.userCardContent {

    width: 80%;
    background: rgba(255, 255, 255, 0.95);
    margin: 0.5em auto;
    border: 1px solid black;
    border-radius: 7px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 5px;
    animation: toLeftCardUser 0.8s;

}

@keyframes toLeftCardUser {

    from {

        width: 50%;

    }

    to {

        width: 80%;

    }
    
}

.userCardContent > h1, .userCardContent > h2, .userCardContent__grupo, .userCardContent__orden, .userCardContent__detalle, .userCardContent__plan {

    text-align: center;

}

.userCardContent__grupo, .userCardContent__orden > h4, .userCardContent__plan {

    padding: 0.3em;
    font-size: 1.2em;

}

.userCardContent > h1 {

    grid-column: 1 / 4;
    grid-row: 1;

}

.userCardContent > h2 {

    grid-column: 4 / 5;
    grid-row: 1;

}

.userCardContent__grupo {

    grid-column: 1 / 2;
    grid-row: 2;

}

.userCardContent__plan {

    grid-column: 2 / 3;
    grid-row: 2;

}

.userCardContent__orden {

    grid-column: 3 / 4;
    grid-row: 2;

}

.userCardContent__detalle {

    width: 90%;
    height: 2em;
    background: rgba(255, 0, 0, 0.7);
    grid-column: 4 / 5;
    grid-row: 2;
    border: 0px;
    border-radius: 4px;
    font-size: 1.2em;
    margin: auto;
    padding: 0.3em;

}

.userCardContent__detalle:hover {

    background: rgba(255, 66, 66, 0.7);
    cursor: pointer;

}

/* deslizar las card hasta su lugar */

@media screen and (max-width: 1300px) {

    .userCardContent__grupo, .userCardContent__orden > h4, .userCardContent__plan {

        padding: 0.3em;
        font-size: 1em;
    
    }
    
}

@media screen and (max-width: 1100px) {

    .userCardContent > h1 {

        font-size: 1.6em;

    }

    .userCardContent__grupo, .userCardContent__orden > h4 {

        padding: 0.3em;
        font-size: 0.8em;
    
    }

    .userCardContent__plan {

        font-size: 0.7em;

    }

    .userCardContent > h2 {

        text-align: center;
        font-size: 1.1em;

    }

    .userCardContent__detalle {

        width: 70%;
        height: 1.5em;
        margin: auto;
        padding: 0;

    }
    
}

@media screen and (max-width: 700px){

    .userCardContent > h1 {

        font-size: 1.3em;

    }

    .userCardContent > h2 {

        text-align: center;
        font-size: 0.8em;

    }

    .userCardContent__grupo > h3 {

        font-size: 1em;

    }

    .userCardContent__detalle {

        width: 50%;
        font-size: 0.8em;

    }
    
}