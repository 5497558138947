.contentProyectsCards {

    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */

    width: 90%;
    height: 60em;
    margin: 0 auto 5em;

    /* border: 1px solid red; */

}

/* .contentProyectsCards:nth-child(1) {

    margin: 10em;

} */

.extra {

    margin-bottom: 30em;

}

.p1 {

    display: flex;

}

.contentImg,
.bodyProyectCard {

    width: 100%;
    height: 20em;
    padding: .5em;

    /* border: 1px solid red; */

}

.contentImg img {

    width: 100%;
    height: 20em;
    object-fit: cover;
    border: 2px solid black;

}

.bodyProyectCard {

    transition: height 1.5s;

}

.contentTxtDesc {

    margin: 1em 0 0;
    text-align: justify;
    transition: height 1.5s;

    /* overflow: auto; */

    /* border: 1px solid red; */

}

.verMas {

    display: none;

    /* padding: .5em 0; */

    /* border: 1px solid greenyellow; */

}

.contentBTN {

    display: flex;
    justify-content: space-between;

    width: 100%;
    margin: .7em 0 0 0;

}

.contentBTN a {

    width: 45%;
}

.ContentListCard {

    position: relative;
    top: 210%;

    width: 89%;
    margin: 0 auto;
    
    z-index: 100;

}

@media screen and (min-width: 768px) {

    .contentProyectsCards {

        /* flex-direction: row; */

        height: 100%;

    }

    /* .contentProyectsCards:nth-child(even) {

        flex-direction: row-reverse;

    } */

    .contentTxtDesc {

        height: 9.7em;
        overflow: hidden;

    }

    .verMas {

        position: relative;
        left: 75%;

        display: flex;
        flex-direction: column;
        justify-content: center;

        width: 7em;
        height: 2em;
        color: rgba(185, 0, 0, 1);
        text-align: center;

    }

    .verMas:before {

        content: "Ver mas...";

    }

    .verMas:hover {

        cursor: pointer;

    }

    /* 
    .verMas::after {

        content: "Ver mas...";

    } */

    .contentTxtDescActive {

        height: 100%;
        /* margin-bottom: 5em; */

    }

    .verMasClose:before {

        content: "Ver menos";

    }

    .extra {
    
        margin-bottom: 20em;
    
    }

    .ContentListCard {
    
        top: 100%;

        width: 100%;
        height: 20em;
        background: rgba(0, 0, 0, 1);
        /* padding: 1.5em 0; */
        padding-top: 5em;
    
    }

}

@media screen and (min-width: 1024px) {

    .contentProyectsCards {

        flex-direction: row;

        /* height: 100%; */

    }

    .contentProyectsCards:nth-child(even) {

        flex-direction: row-reverse;

    }

    .verMas {

        left: 80%;

    }

    .ContentListCard {
        
        margin-top: 6em;
    
    }

    .extra {
    
        margin-bottom: 25em;
    
    }
    
    .ContentListCard {

        position: absolute;
        top: 33em;
        left: 0%;
    
    }

}

@media screen and (min-width: 1200px) {

    .bodyProyectCard {

        height: 100%;

    }

    .contentTxtDesc {

        height: 100%;

    }

    .verMas {

        display: none;

    }

}

@media screen and (min-width: 1440px) {

    /* .extra {
    
        margin-bottom: 15em;
    
    } */

    .ContentListCard {

        top: 38em;
        /* left: 5%; */
        
        /* width: 90%; */
        margin-top: 1em;
    
    }
    
}

@media screen and (min-width: 2560px) {

    .ContentListCard {
        
        margin-top: 1em;
    
    }
    
}