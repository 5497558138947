.contReservas {

    width: 100%;
    height: 100vh;
    background: url(../../../images/fondos/20-12\ FONDO\ WEB-02.jpg) no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    border: 1px solid #000000;
    text-align: center;

}

.contReservas__fil {

    width: 80%;
    background: rgba(255, 255, 255, 0.8);
    margin: 0 auto;
    padding: .5em 0;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    border: 1px solid #999;
    border-radius: 0 0 3em 3em;
    border-top: 0;

}

.contReservas__fil > h1 {

    grid-column: 1 / 5;

}

.fill__toggleButtonGroup {

    padding: 0 1.5em 0 0;

}

.contReservas__contResCard {

    width: 80%;
    margin: 0.5em auto;

}

/* @media screen and (min-width: 1400px) {

    .contReservas__fil {

        grid-template-columns: repeat(4, 1fr);

    }

    .contReservas__fil > h1 {

        grid-column: 1 / 4;

    }
    
}

@media screen and (max-width: 1399px) {

    .contReservas__fil {

        grid-template-columns: repeat(3, 1fr);

    }

    .contReservas__fil > h1 {

        grid-column: 1 / 3;

    }
    
}

@media screen and (max-width: 1100px) {

    .contReservas__fil {

        grid-template-columns: repeat(2, 1fr);

    }

    .contReservas__fil > h1 {

        grid-column: 1 / 2;

    }
    
}

@media screen and (max-width: 700px) {

    .contReservas__fil {

        grid-template-columns: repeat(3, 1fr);

    }

    .contReservas__fil > h1 {

        grid-column: 1 / 3;

    }

    .fill__toggleButtonGroup {

        padding: 0 1.5em 0 0;

    }
    
} */