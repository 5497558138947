.TestimonyContent {

    width: 100%;
    height: 100vw;
    background: url(../../../images/fondos/20-12\ FONDO\ WEB-02.jpg) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    border: 1px solid #000000;
    text-align: center;

}

.contentNav {

    background: rgba(255,255,255,0.7);

}

.contentList {

    display: grid;
    grid-template-columns: repeat(2, 1fr);

}

.ContentHead {

    background: rgba(255, 255, 255, 0.7);
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: .5em;

}

.ContentHead h1 {

    width: 20%;

}

.contentRating {

    width: 15%;
    display: flex;

}

.Select {

    width: 20%;

}

.mnu_icon {

    display: flex;

}

.mnu_icon:hover {

    cursor: pointer;

}

.list {

    /*Positioning*/
    position: absolute;
    top: 5.2em;
    right: 19.5em;
    z-index: 3;

    /*Box-Model*/
    width: 0px;
    height: 0px;
    padding: .3em;

    /*Tipography*/
    color: rgba(255, 255, 255, 0);

    /*visual*/
    background:rgba(0, 0, 0, 0.8);
    border: 1px solid #000;
    border-radius: 5px;
    list-style: none;
    opacity: 0;

    /*Misc*/
    transition: all 0.5s;

}

.list li {

    /*Box-Model*/
    padding: .2em;

}

.list li:hover {

    /*Box-Model*/
    border-radius: 5px;

    /*Visual*/
    background: rgba(255, 255, 255, 0.3);
    cursor: pointer;

}

.popupConfig {

    /*Positioning*/
    position: absolute;
    left: 40%;
    z-index: 5;

    /*Box-Model*/
    width: 20%;

    /*Visual*/
    background: rgba(70, 70, 70, 0.9);
    border: 1px solid black;
    border-radius: 20px;
    opacity: 0;

    /*Misc*/
    transition: all .7s;

}

.popup_form {

    /*Box-Model*/
    width: 90%;
    height: 13em;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin: .5em auto;

}

.popup_form input {

    /*Box-Model*/
    height: 3em;
    padding: 0 .5em;

    /*Visual*/
    border-radius: 5px;

}

.popup_form input[type="number"] {

    /*Box-Model*/
    width: 50%;
    margin: 0% auto;

    /*Tipography*/
    text-align: center;

}

.popup_form input[type="submit"] {

    /*Box-Model*/
    width: 100%;

    /*Visual*/
    border: 1px solid black;

}

