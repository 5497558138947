nav, nav a, nav ul a {

  padding: 0px;
  margin: 0px;
  height: auto;
  font-size: auto;

}

/*===== Header =====*/

.header {

  height: 5em;
  background:rgba(0, 0, 0, 1);
  /* padding: 0em 2em; */

}

.menuBar {

  display: flex;
  justify-content: space-evenly;

  width: 70%;
  height: 5em;
  margin: 0 auto;

}

.navMenu {

  display: flex;
  /* justify-content: center; */

  /* width: 40%; */

}

.navRight {

  display: flex;
  justify-content: space-between;
  
  width: 50%;
  list-style: none;

}

.navRight li {

  /* display: flex;
  flex-direction: column;
  justify-content: center; */
  margin: auto;
  background-image: linear-gradient(red, red);
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 0% 2px;
  transition: background-size .3s;
  
}

.navRight li:hover {

  background-size: 100% 2px;

}

.navRight li p {

  font-size: 1em;

}

.menuBtnS {
  display: none;
}

/*===== Logo Importainer =====*/

.logo-princ {

  display: flex;
  
  height: 70px;
  width: 130px;

}

.logoP img {
  
  width: 100%;
  margin: .7em 0 0 0;

  /* border: 1px solid blue; */

}

.show {

  transform: translateX(0%);
  background: rgba(0, 0, 0, .7);
  backdrop-filter: blur(2px);

}

/*===== Responsive =====*/

/* @media screen and (max-width: 1000px) {

  .navRight li p {
  
    font-size: .8em;
  
  }

} */

@media screen and (max-width: 800px) {

  .header {

    padding: .5em 1em;

  }

  .menuBar {
  
    justify-content: space-between;

    width: 100%;
  
  
  }

  .logoP img {

    height: 4em;
    margin: 0;
    
  }

  .navRight {
    position: fixed;
    flex-direction: column;
    z-index: 100;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.61);
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    transform: translateX(500%);
    transition: transform 0.8s ease-in-out;
  }

  .navRight.show {
    transform: translateX(0%);
    z-index: 100;
  }

  .navRight ul {
    display: block;
    position: absolute;
  }

  .navRight a {
    margin-top: 80px;
    display: block;
    text-align: center;
    font-size: 30px;
    color: #ffffff;
  }

  .logo-princ {
    margin-left: 0px;
  }

  .menuBtnS {
    display: flex;
    position: absolute;
    top: 32px;
    right: 30px;
    color: #ffffff;
    font-weight: 100;
    z-index: 100;
  }

  .fa-times {
    transition: 2500ms;
    color: #ffffff;
  }
  /* .header {
    margin-bottom: 50px;
  } */
}

@media screen and (max-width: 430px) {

  .logo-P img {

    width: 15em;
    
  }

  .nav-right a {
    margin-top: 50px;
    font-size: 20px;
  }

  .menuBar {
  
    display: flex;
    justify-content: space-between;
  
  
  }

  /* .menuBtnS {
    
    right: -40%;

  } */
}
