.contentBTN {

    display: flex;
    justify-content: space-between;

    width: 100%;
    margin: .7em 0 0 0;

}

.btnVer {

    background: rgba(0, 0, 0, 1);
    color: white;

}

.btnVer,
.btnCon {

    display: flex;
    justify-content: center;

    /* width: 20em; */
    border: 1px solid black;
    margin: 0 0 1em 0;
    padding: .5em;
    font-weight: bold;

}

.btnCon {

    width: 45%;

}

.btnCon p {

    color: black !important;

}

.btnVer:hover,
.btnCon:hover,
.btnCon:hover p {

    background: rgba(185, 0, 0, 1);
    color: white !important;
    cursor: pointer;

}