
.LogInContent {

    width: 100%;
    height: 100vw;
    background: url(../../../images/fondos/20-12\ FONDO\ WEB-02.jpg) no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    border: 1px solid #000000;

}

.formLogIn {

    width: 70%;
    padding: 0.5em;
    text-align: center;
    box-sizing:content-box;
    margin: 10% auto;
    font-size: 1em

}

.formLogIn > input[type="email"], input[type="password"] {

    width: 80%;
    height: 3em;
    border: 1px solid #000000;
    border-radius: 3px;
    margin: 0.5em 0;
    padding: 0.5em;

}

.formLogIn > input[type="submit"] {

    width: 80%;
    height: 3em;
    background: #000000;
    font-size: 1em;
    color: #fff;
    border-radius: 3px;

}