.container {

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    /* background: linear-gradient(45deg, rgba(255, 255, 255, 1), rgba(171, 178, 185, 1)); */
    background:rgba(0, 0, 0, 1);
    /* margin: 2em 0 1em; */
    padding: 5em 0 0;

}

.contentImg {

    width: 90%;
    margin: 0 auto;
    /* height: 20em;
    
    border: 1px solid red;
    background-color: red; */

}

.contentImg img {

    width: 100%;

}

.content {
    
    display: flex;
    flex-direction: column;
    justify-content: center;

    color: white;
    width: 90%;
    /* height: 20em; */
    text-align: justify;
    margin: 3em auto;

}

.content h2 {

    font-size: 1.2em;

    margin-bottom: 1em;
    text-align: justify;

}

.content p {

    font-size: 1em;

}

@media screen and (min-width: 1024px) {

    .container {

        flex-direction: row;
    
    }

    .contentImg {
    
        width: 45%;
    
    }

    .content {
        
        display: flex;
        flex-direction: column;
        justify-content: center;
    
        color: white;
        width: 45%;
        height: 20em;
        text-align: justify;
        margin: 0 auto;
    
    }

    .content p {
    
        font-size: 1em;
    
    }
    
}