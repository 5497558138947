.contGralFooter {

    display: flex;
    align-items: flex-end;

    height: 12em;

}

.containerFooter {

    width: 100%;
    height: 6em;
    background-color: black;
    transition: height 1s;

}

.btnFooter {

    position: relative;
    top: -1.5em;
    left: 27%;

    display: flex;
    flex-direction: column;
    justify-content: center;

    font-size: 1.2em;
    font-weight: bold;
    text-align: center;
    color: white;
    text-shadow: -1px 2px 8px rgba(0, 0, 0, 1), 4px 0px 8px rgba(0, 0, 0, 1), 0px -4px 8px rgba(0, 0, 0, 1);

    width: 7em;
    height: 2.5em;
    background-color: black;
    border-radius: 0.3em;
    border: 1px solid black;
    transform: perspective(2em) rotateX(5deg);

}

.btnFooter:before {

    content: "VER MÁS"

}

.btnFooter:hover {

    /* color: red; */

    background: linear-gradient(white, black 45%);

    cursor: pointer;

}

.btnFooterClouse:before {

    content: "VER MENOS"

}

.containerBodyF {

    position: relative;
    bottom: 2em;

    height: 3em;
    transition: all 1s;

}

.containerLink {

    position: absolute;
    left: -100%;
    bottom: 0;

    width: 90%;
    transition: all 1s;

    /* border: 1px solid green; */

}

.containerLink ul {

    display: grid;
    grid-template-rows: repeat(3, 1fr);
    grid-auto-flow: column;

    height: 7em;

}

.containerLink ul a {

    display: flex;
    flex-direction: column;
    justify-content: center;

}

.containerLink ul li {

    background-image: linear-gradient(red, red);
    background-repeat: no-repeat;
    background-position: 0% 100%;
    background-size: 0% 2px;
    transition: background-size .5s;
    list-style: none;

}

.containerLink ul li p {

    font-size: .9em;

}

.containerLink ul li:hover {

    background-size: 100% 2px;

}

.social {

    position: relative;
    left: 0%;

    display: flex;
    justify-content: center;

    width: 100%;
    transition: all 1s;
    /* border: 1px solid red; */

    cursor: pointer;

}

.social {

    margin: auto 0;

}

.icon {
    display: flex;
    height: 2em;
    width: 2em;
    margin: 7px;
    border: 1px solid;
    color: var(--negro-texto);
    border-radius: 25px;
    justify-content: center;
    align-items: center;
    font-size: 18px;
}

.icon:hover {
    color: var(--rojo-botones);
    transition: 500ms;
}

.icon a {

    color: var(--negro-texto);

}

.icon:hover a {

    color: var(--rojo-botones);

}

.footerActive {

    height: 12em;

}

.footerActive .btnFooter::before {

    font-size: .9em;

    content: "VER MENOS";

}

/* .footerActive .social {

    left: 70%;

    width: 30%;

} */

.footerActive .containerLink {

    left: 5%;

}

.footerActive .containerBodyF {

    height: 11em;

}

.contEmail {
    
    display: none;

}

@media screen and (min-width: 768px) {

    .contGralFooter {

        display: flex;
        align-items: flex-end;

        height: 11em;

    }

    .btnFooter {

        left: 43%;

        width: 10em;

    }

    .containerBodyF {
    
        position: relative;
        bottom: 1.5em;
    
        height: 3em;
        transition: all 1s;
    
    }

    .icon {
        display: flex;
        height: 50px;
        width: 50px;
        margin: 7px;
        border: 1px solid;
        color: var(--negro-texto);
        border-radius: 25px;
        justify-content: center;
        align-items: center;
        font-size: 18px;
    }

    .containerLink {
    
        position: absolute;
        bottom: 2em;
        left: -100%;
    
        width: 50%;
        transition: all 1s;
    
        /* border: 1px solid green; */
    
    }

    .containerLink ul {
    
        display: grid;
        grid-template-rows: repeat(3, 1fr);
        grid-auto-flow: column;
        grid-gap: 1em 10em;
    
    }

    .containerLink ul li {
    
        width: 60%;
    
    }

    .footerActive {

        height: 11em;

    }

    .footerActive .containerBodyF {
    
        height: 9em;
    
    }

    .footerActive .social {
    
        left: 70%;
    
        width: 30%;
    
    }

    .footerActive .containerLink {
    
        /* bottom: -56em; */
        left: 10%;
    
    }

    .contEmail {
        
        position: relative;
        bottom: 5em;
        left: 100%;
    
        display: flex;
    
        width: 20%;
        color: #fff;
        opacity: 0;
        transition: opacity 1s, left 1.5s;
    
        z-index: 1000;
    
    }
    
    .footerActive .contEmail {
    
        left: 70%;
    
        opacity: 1;
    
    }

}

@media screen and (min-width: 1024px) {
    
    .footerActive .contEmail {
    
        left: 78%;
    
    }
    
}

@media screen and (min-width: 1440px) {

    .btnFooter {

        left: 45%;

    }
    
}