.boxGral {

    margin: 2em 0;

}

.boxGral h2 {

    font-size: 1.3em;
    text-align: center;

    width: 90%;
    margin: 1em auto;

}

.containerEnt {

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: .5em;

    width: 95%;
    margin: 0 auto;
    /* border: 1px solid red; */

}

/* @media screen and (min-width: 768px) {

    .containerEnt {
    
        grid-template-columns: repeat(2, 1fr);
    
    }
    
} */

@media screen and (min-width: 1024px) {

    .containerEnt {
    
        grid-template-columns: repeat(4, 1fr);
    
    }
    
}