.titulo {
  /* margin-top: 0px; */
  font-size: 40px;
  text-align: center;
  margin: 60px 0;
}

@media screen and (max-width: 800px) {
  .titulo {
    font-size: 35px;
  }
}
