.cardProductContent {

    position: relative;

    background-color: #ffffff;
    width: 100%;
    height: 42em;
    overflow: hidden;
    border: solid 0.5px rgb(225, 225, 225);
    /* border-radius: 5px; */
    margin: 0 auto;

}

.headband {

    position: absolute;
    top: 3em;
    right: -16%;
    
    width: 20em;
    background: rgba(145, 145, 145, 0.8);
    border: 1px solid rgb(202, 202, 202);
    color: #ffffff;
    padding: .1em;
    text-align: center;

    transform: rotate(45deg);

}

.headband h3 {

    font-weight: 1000;
    font-size: 1.3em;

}

.OfertaCardTitle {
    margin-top: 15px;
    margin-bottom: 15px;
    margin-left: 10px;
}

.OfertaCardTop {
    position: relative;
    display: flex;
    align-items: center;
    height: 60px;
}

.Descuento {
    position: absolute;
    right: 20px;
    color: rgb(255, 0, 0);
    font-size: 25px;
}

.OfertaContImg {
    /* width: 93%; */
    margin-left: auto;
    margin-right: auto;
}

.cardProductContent img {

    width: 100%;
    height: 15em;
    object-fit: cover;
    /* border-radius: 8px; */

}

.OfertaCarac {

    display: flex;
    justify-content: space-around;

    margin: .5em 0 0 0;
    font-size: 1.1em;

}

.OfertaCardSubtitle {

    font-size: 1em;
    text-align: center;
    margin: .5em 0;

}

.contentCuot,
.contentPrecios {

    width: 95%;
    background: rgba(232, 232, 232, 0.8);
    margin: 1em auto;
    padding: .5em 1em;

}

.contentPrecios {

    display: grid;
    grid-template-columns: repeat(3, 1fr);

    font-size: .9em;

}

.preText {

    grid-column: 1 / 3;

}

.preNum {

    display: flex;
    justify-content: flex-end;

}

.preText, .preNum {

    font-size: 1em;

}

.contentBtn {

    display: flex;
    justify-content: space-around;

}

.btnVer,
.btnCon {

    display: flex;
    justify-content: center;

    width: 35%;
    border: 1px solid black;
    margin: 0 0 1em 0;
    padding: .5em;
    font-weight: bold;

}

.btnVer {

    background: rgba(0, 0, 0, 1);
    color: white;

}

.btnCon p {

    color: black !important;

}

.btnVer:hover,
.btnCon:hover,
.btnCon:hover p {

    background: rgba(185, 0, 0, 1);
    color: white !important;
    cursor: pointer;

}

.sangria {
    text-indent: 1em;
    text-decoration: none;
}

.OfertaCardDesc {
    width: 90%;
    margin: auto;
    margin-top: 15px;
}

.PopupContent {

    width: 70em;
    background: rgba(232, 232, 232, .8);
    border: 1px solid rgba(185, 0, 0, 1);
    margin: 1em 0;

}

.PopupClouse {

    width: 1.5em;
    height: 1.5em;
    border: 1px solid rgba(185, 0, 0, 1);
    margin: 0 .5em;

}

.PopupClouse:hover {

    cursor: pointer;

}

.PopupHeader {

    display: flex;
    flex-direction: column;
    justify-content: center;

    padding: 1em 5em;

}

.PopupHeader img {

    height: 25em;

}

/* -------------------- POP UP ----------------------------- */

.contentPopup {

    position: fixed;
    top: 100vh;
    left: 15%;

    width: 10%;
    height: 10%;
    background: rgba(232, 232, 232, 0.5);
    opacity: 0;
    transition: all .7s ease-in-out;

    z-index: 100;

    border: 1px solid white;

}

.maxWidth {

    top: 1em;

    width: 70%;
    height: 70%;
    opacity: 1;

}

.closePop {

    display: flex;
    justify-content: flex-end;

    background: rgba(0, 0, 0, 1);
    margin: 0 0 .5em;
    padding: .2em;

}

.closePopIcon {

    display: flex;

    padding: 0;
    /* border: 1px solid red; */

}

.closePopIcon:hover {

    cursor: pointer;

}

.containerP {

    width: 90%;
    height: 92%;
    margin: 0 5%;
    /* filter: blur(2em); */
    overflow: scroll;

}

.contentImg {

    height: 70%;
    margin: 0 auto;

    /* border: 1px solid red; */

}

.imgSlider {

    position: relative;

    display: flex;

    height: 100%;
    transform: translateX(0em);
    transition: transform 2s;

    /* border: 1px solid blue; */

}

.imgEffectOff {

    width: 100%;
    height: 26em;

}

.imgSlider img {

    min-height: 100%;
    min-width: 100%;
    margin: auto;
    object-fit: cover;
    /* transform: translateX(0em);
    transition: transform 2s; */

}

.contentDet {

    width: 100%;
    height: 2.5em;
    background:rgba(232, 232, 232, 0.9);
    color: black;
    border-bottom: 1px solid black;
    margin: .3em auto;
    transition: height 1s;

}

.detHead:hover {

    cursor: pointer;

}

.detHead {

    display: flex;
    justify-content: space-between;
    align-items: center;

}

.detHead p {

    width: 95%;
    height: 2em;
    padding: .3em;
    font-weight: bold;

}

.iconArrow {

    transition: transform .5s;

}

.iconGiro {

    transform: rotate(180deg);

}

.detProd {

    width: 95%;
    height: 100%;
    margin: .5em auto;
    opacity: 0;
    transform: translateX(100em);

    transition: transform .3s, opacity .2s;

}

.detProd ul {

    margin: 0 0 1em 0;
    list-style: none;

}

.detProd ul li {

    margin: 0 0 .2em 0;

}

.detProd p {

    font-weight: bold;
    text-decoration: underline;
    margin: 1em 0;

}

.detList {

    height: 25em;

}

.detList p {

    text-decoration: underline;

}

.detList .detProd {

    transform: translateX(0em);
    opacity: 1;
    transition: transform .5s, opacity 2s;

}

/* prueba */

.containerListDes {

    color: #fff;

}

.item{

    background: red;

}

/* prueba */

/* ===== Responsive ===== */

/* @media screen and (min-width: 600px) {

    .cardProductContent {

        width: 70%;

    }

    .cardProductContent img {

        height: 20em;

    }

} */

/* @media screen and (min-width: 800px) {

    .cardProductContent {
        width: 90%;
    }

    .OfertaCardTop {
        position: relative;
        display: flex;
        flex-direction: column-reverse;
        align-items: start;
    }

    .Descuento {
        margin: 0 0 2% 5%;
        left: 0;
    }

    .OfertaCardTitle {

        margin: 0 0 1.4em 5%;

    }

    .OfertaCardTitle,
    .Descuento {

        font-size: 1.3em;

    }

    .OfertaCardDesc {

        font-size: 1em;

    }

} */

/* @media screen and (min-width: 1330px) {

} */