.ContainerCard {

    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */

    width: 100%;
    /* height: 15em; */
    /* margin: 0 1em; */
    margin-bottom: 2em;
    padding: 1em 1.5em;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);

    /* border: 1px solid red; */

}

.ContainerCard h3 {

    margin: 1em 0;

}

@media screen and (min-width: 1024px) {

    .ContainerCard {

        width: 30%;
    
    }
    
}