.contentBannerTest {

    width: 100%;
    margin: 0 auto;

}

.contentTiVi,
.txtGroup1,
.txtGroup2 p,
.txtGroup3 {

    width: 90%;

}

.txtGroup1 {

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    width: 70%;
    /* height: 15em; */
    margin: auto;

}

.txtGroup1 p {

    font-size: .9em;

    margin-top: 1.5em;
    text-align: justify;

}

.contentTiVi {

    margin: 0 auto;

}

.contentTiVi h1 {

    font-size: 1.5em;

    margin: 1em 0;

}

.contentTiVi h3 {

    font-size: 1em;

}

.contentView {

    height: 30em;
    margin: 1em auto;

}

.txtGroup2 {

    background: rgba(185, 185, 185, 0.7);
    margin: 1em auto;
    padding: 1em 0;

}

.headFooter {

    display: flex;
    flex-direction: column;

    height: 10em;
    background: rgba(255, 255, 255, 1);
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    margin: 3em 0;
    padding: .2em;

}

.linkForm {

    display: flex;
    justify-content: flex-start;

    /* width: 30%; */
    color: var(--blanco-titulo);
    /* font-size: .9em; */
    padding: 0.5em;
    text-decoration: underline;

}

.linkForm p {

    color: #000;

}

.contentFormFooter {

    /* width: 25%; */

}

.linkForm,
.contentFormFooter {

    display: flex;
    flex-direction: column;
    justify-content: center;

    height: 3em;
    margin: auto;

}

.linkForm p,
.contentFormFooter p {

    font-size: 1em;
    font-weight: bold;

}

.btnCtaFooter {

    position: relative;
    left: 0em;

    display: flex;
    justify-content: space-around;

    border: 1px solid red;
    background: rgba(200, 10, 10, 1);
    padding: 0.5em;
    transition: left 1s;

}

.btnCtaFooter:hover {

    left: 2em;

    cursor: pointer;

}

.btnCtaFooter p {

    color: #fff;

}

.btnIcon {

    display: flex;
    flex-direction: column;
    justify-content: center;

    margin: 0 1em;

}

@media screen and (min-width: 768px) {

    .headFooter {

        flex-direction: row;
        justify-content: space-around;

        height: 7em;

    }

    .linkForm p,
    .contentFormFooter p {

        font-size: 1.2em;

    }

}

@media screen and (min-width: 800px) {

    .contentTiVi h3 {

        font-size: 1.2em;

    }

    .txtGroup1 p {

        font-size: 1em;

    }

}

@media screen and (min-width: 1000px) {

    .contentTiVi h1 {

        font-size: 2em;

    }

}