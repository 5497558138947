.contentLog {

    display: flex;
    justify-content: center;
    flex-direction: column;

    width: 100%;
    height: 100vh;
    background: rgb(192, 192, 192);

}

.formLog {

    width: 30%;
    margin: 0 auto;
    padding: 1em 2em;
    border: 1px solid black;
    text-align: center;

}

.formLog input {

    width: 100%;
    height: 2em;
    margin: .5em 0;

}

.formLog input[type="submit"]:hover {

    cursor: pointer;

}