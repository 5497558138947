
.editarContent {

    width: 100%;
    height: 100vh;
    background: url(../../../images/fondos/20-12\ FONDO\ WEB-02.jpg) no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    border: 1px solid #000000;
    text-align: center;

}

.editarContent > h1 {

    width: 80%;
    background: linear-gradient(90deg, rgba(2,0,36,0) 0%, rgba(255,255,255,1) 50%, rgba(0,212,255,0) 100%);
    margin: 0.5em auto;

}

.editarContent-formCont {

    width: 70%;
    background: rgba(255, 255, 255, 0.9);
    margin: auto;
    padding: 1em;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1.5em;
    border: 1px solid #868686;
    box-shadow: 5px 5px 5px #000000;
    align-items: center;
    font-weight: bold;

}

.editarContent-formCont > input {

    height: 2.5em;

}

.editarContent-formCont > textarea {

    color: #000000;
    border: 1px solid #000000;

}

.editarContent-formCont > textarea::placeholder {

    color:#000000;

}

.editarContent-formCont > img {

    width: 30%;
    margin: 0 auto;

}

.editarContent-Buton {

    width: 10%;
    height: 2em;
    background: rgba(255, 255, 255, 0.8);
    font-weight: bold;
    margin: 2em 0;
    position: relative;
    left: 30%;
    border: 1px solid black;

}

.editarContent-Buton:hover {

    background:rgba(255, 0, 0, 0.7);
    color: white;
    cursor: pointer;
    border: 1px solid red;

}