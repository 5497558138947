.content {

    width: 100vw;
    height: 100vh;
    background: url(../../../images/fondos/20-12\ FONDO\ WEB-02.jpg) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    border: 1px solid #000000;
    text-align: center;

}

.box {

    width: 60%;
    background: rgba(255, 255, 255, 0.95);
    margin: 5% auto;
    padding: 1em;
    border: 1px solid red;
    border-radius: 1em;

}

@keyframes title {

    from {

        top: 300px;

    }

    to {

        top: 0px;

    }
    
}

.box > p {

    width: 90%;
    margin: 0 auto;
    position: relative;
    animation: title 2s;

}

.ratingGroup {

    display: flex;
    margin: 1em 0;

}

@keyframes active {

    from {

        left: 80%;

    }

    to {

        left: 0px;

    }
    
}

.rating {

    width: 100%;
    position: relative;
    margin: 1em 1.5em 0 0;
    /* display: block; */
    animation: active 2s;

}

.towGorup {

    display: grid;
    grid-template-columns: repeat(2, 1fr);

}

.towGorup > div {

    width: 60%;
    text-align: left;
    margin: 0 auto;

}

.rating > h3 {

    width: 100%;
    margin: 0 0 1em 0

}

.rating > input {

    width: 80%;
    height: 3em;
    background:rgba(255, 0, 0, 0.8);
    color: #fff;
    font-size: 1.2em;
    border: 1px solid red;
    margin: 1em 0 0 0;
    padding: 0;

}

.rating > input:hover {

    cursor: pointer;

}

.desactiv {

    display: none;

}

.inputMot {

    height: 2em;
    grid-column: 1 / 3;
    margin: 1em 0 0 0;
    display: none;

}

@media screen and (max-width: 1300px) {

    .towGorup > div {

        width: 90%;

    }
    
}

@media screen and (max-width: 1000px) {
    
    .box {

        width: 80%;

    }

    .towGorup > div {

        width: 100%;

    }
    
}

@media screen and (max-width: 800px) {

    .content {

        height: 100vh;

    }

    .towGorup > div {

        width: 60%;
        grid-column: 1 / 3;
        margin: .5em auto;

    }
    
}

@media screen and (max-width: 600px) {

    .box {

        width: 80%;

    }

    .towGorup > div {

        width: 80%;
        padding: 0;
        margin: 0 auto;

    }
    
}

@media screen and (max-width: 400px) {

    .box {

        width: 90%;

    }

    .towGorup > div {

        width: 100%;
        padding: 0;

    }
    
}